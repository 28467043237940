import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import { NavigationBar } from '../components';
import AppStore from '../stores/AppStore';
import { Container, Grid, Segment } from 'semantic-ui-react';
import { ToastContainer, toast } from 'react-toastify';
import { SelectionMenu, BackgroundImage, LiveView, LiveViewArtist, ColorPallet } from '../components';
import "react-toastify/dist/ReactToastify.css";
import { version } from '../../package.json'
import CTABanner from '../components/CTABanner'

/** TOASTIFY CONFIG */
toast.configure({
  autoClose: 5000,
  draggable: false,
});
window.toast = toast;
if (!window.SERVICES) window.SERVICES = {};
window.SERVICES.notify = (msg, type = "INFO") => {
  if (type == "INFO") console.log('[INFO] ' + msg);
  if (type == "WARN") console.warn('[WARN] ' + msg);
  if (type == "ERR") console.error('[ERR] ' + msg);
  if (type == "SUCCESS") console.log('[INFO] ' + msg);
  window.toast(
    <div>
      <div>
        <div className="toast-heading-container">
          <div className={"toast-indicator-" + type.toLowerCase()}>
          </div>
          <strong className="mr-auto">{type}</strong>
        </div>
      </div>
      <div>
        <div>
          {msg}
        </div>
      </div>
    </div>,
    {
      className: "toast-class-name",
      progressClassName: "toast-progress-class-name-none",
      position: toast.POSITION.BOTTOM_CENTER
    }
  )
}


const VersionDisplay = styled('div')`
  position: fixed !important;
  bottom: 0px !important;
  right: 0px !important;
  z-index: 100 !important;
  background: var(--app-container-version-disp-bg) !important;
  color: var(--app-container-version-disp-txt-A) !important;
  padding: 7px !important;
  font-size: 0.8rem !important;
  font-family: robo-bold !important;
`

const CustomContainer = styled('div')`
  width: 100% !important;
  height: 100% !important;
  max-height: 100vh !important;
  min-height: 100% !important;
  min-width: fit-content !important;

  border: unset !important;
  box-shadow: unset !important;
`

const CustomSegmentGroup = styled(Segment.Group)`
  height: inherit !important;
  width: inherit !important;
  padding: 0px !important;
  border: unset !important;
  box-shadow: unset !important;
`

const NoPaddingSegment = styled(Segment)`
  padding: 0px !important;
  border: unset !important;
  box-shadow: unset !important;
  border-radius: unset !important;
`
const CustomLiveViewArtistSegment = styled(NoPaddingSegment)`

`

const HeaderSegment = styled(NoPaddingSegment)``
const BodySegment = styled(NoPaddingSegment)`
  height: calc(100vh) !important;
  background-color: var(--body-bg) !important;
`
const BodyLeftSegment = styled(NoPaddingSegment)`
  height: inherit !important;
  border: unset !important;
  box-shadow: unset !important;
  display: flex !important;
  margin: auto;
  background-color: var(--app-container-bg-left) !important;

`
const BodyRightSegment = styled(NoPaddingSegment)`
  height: 100% !important;
  border: unset !important;
  box-shadow: unset !important;
  background-color: var(--app-container-bg-right) !important;
`

const CustomGrid = styled(Grid)`
  margin: unset !important;
  height: inherit;
  background-color: var(--app-container-bg);
`
const CustomGrid2 = styled(Grid)`
  margin: unset !important;
  padding: 0px !important;
  width: 100%;
`

const CustomGridColumn = styled(Grid.Column)`
  display: flex;
  overflow: auto !important;
  background-color: var(--app-container-bg);
`


const initialState = {};

export class AppContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = initialState;
  }

  render() {
    return (
      <div>
        <VersionDisplay>{`v${version}`}</VersionDisplay>
        <CustomContainer style={{ margin: '0em !important' }}>
          <CustomSegmentGroup className="app-container-custom-segment-group">
            <HeaderSegment><NavigationBar /></HeaderSegment>
            <BodySegment>
              <CustomGrid
                className={`${!(AppStore.currentPage == AppStore.PagesEnum.MAIN) ? 'app-container-custom-grid2' : 'app-container-custom-grid center-content'}`}
                columns={2} relaxed stretched doubling stackable>
                <CustomGridColumn width={(AppStore.currentApp == AppStore.AppsEnum.ARTIST) ? 8 : 8}>
                  <BodyLeftSegment centered textAlign="center">
                    {
                      (AppStore.currentPage == AppStore.PagesEnum.MAIN) &&
                      <SelectionMenu />
                    }
                    {
                      !(AppStore.currentPage == AppStore.PagesEnum.MAIN) &&
                      !((AppStore.currentPage == AppStore.PagesEnum.MODEL) &&
                        (AppStore.currentApp == AppStore.AppsEnum.ARTIST)) &&
                      <LiveView />

                    }
                    {
                      ((AppStore.currentPage == AppStore.PagesEnum.MODEL) &&
                        (AppStore.currentApp == AppStore.AppsEnum.ARTIST) &&
                        (AppStore.currentModel != -1)) &&
                      <LiveViewArtist isSolo={true} model={AppStore.currentModel} />

                    }
                    {
                      (AppStore.currentPage == AppStore.PagesEnum.MODEL) &&
                      (AppStore.currentApp == AppStore.AppsEnum.ARTIST) &&
                      (AppStore.currentModel === -1) &&
                      <CustomGrid2 className="live-view-grid-4-model-container" columns={2}>
                        <Grid.Column ><LiveViewArtist model={1} /></Grid.Column>
                        <Grid.Column ><LiveViewArtist model={2} /></Grid.Column>
                        <Grid.Column ><LiveViewArtist model={3} /></Grid.Column>
                        <Grid.Column ><LiveViewArtist model={4} /></Grid.Column>
                      </CustomGrid2>
                    }
                  </BodyLeftSegment>
                </CustomGridColumn>
                <CustomGridColumn width={(AppStore.currentApp == AppStore.AppsEnum.ARTIST) ? 8 : 8}>
                  <BodyRightSegment centered textAlign="center">
                    {
                      (AppStore.currentPage == AppStore.PagesEnum.MAIN) &&
                      <BackgroundImage />
                    }
                    {
                      !(AppStore.currentPage == AppStore.PagesEnum.MAIN) &&
                      <ColorPallet />
                    }
                  </BodyRightSegment>
                </CustomGridColumn>
              </CustomGrid>
              <div className="ctaBannerContainer">
                  <CTABanner></CTABanner>
              </div>
            </BodySegment>
          </CustomSegmentGroup>
        </CustomContainer>
      </div>
    );
  }
}

AppContainer = observer(AppContainer);