import * as React from "react";
import styled from "@emotion/styled";
import { observer } from "mobx-react";
import { Icon, Table, Accordion } from "semantic-ui-react";
import {
  Segment,
  Grid,
  Input,
  Button,
  Dropdown,
  Image,
  Popup,
} from "semantic-ui-react";
import { SketchPicker } from "react-color";
import ProductStore from "../stores/ProductStore";
import AppStore from "../stores/AppStore";
import $ from "jquery";
import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import Tooltip from "rc-tooltip";
import Slider from "rc-slider";

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
const Handle = Slider.Handle;

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </Tooltip>
  );
};

const ColorIndicator = styled("div")`
  width: 10px;
  height: 10px;
  margin: 1px auto 0px auto;
  border-radius: 10px;
`;

const CustomButton = styled(Button)`
  display: flex !important;
  text-align: center;
  margin: auto;
`;
const Cover = styled("div")`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

const PopOver = styled("div")`
  position: absolute;
  z-index: 15;
`;

const CustomTableBody = styled(Table.Body)`
  font-family: robo-reg;
`;
const SelectColorText = styled("div")`
  margin-right: 10px;
`;

const CustomSegmentPopup = styled(Segment)`
  text-align: center;
  margin: auto;
  display: block;
  font-size: 1.2rem;
  font-family: robo-reg;
`;

const Masks = {
  Foundation: [
    {
      name: "foundation_fullface",
      text: "Full Face",
      value: "foundation_fullface",
    },
    {
      name: "foundation_conceal_1",
      text: "Perfector",
      value: "foundation_conceal_1",
    },
  ],
  "Foundation 2": [
    {
      name: "foundation_fullface",
      text: "Full Face",
      value: "foundation_fullface",
    },
    {
      name: "foundation_conceal_1",
      text: "Perfector",
      value: "foundation_conceal_1",
    },
  ],
  Blush: [
    {
      name: "blush_1",
      text: "Apples of cheeks",
      value: "blush_1",
      image: { avatar: true, src: "/Masks/blush_1.png" },
    },
    // {
    //   name: "blush_apple_of_cheek_style1",
    //   text: "Apples of cheeks Style 2",
    //   value: "blush_apple_of_cheek_style1",
    //   image: { avatar: true, src: "/Masks/blush_1.png" },
    // },
    // {
    //   name: "blush_apple_of_cheek_style2",
    //   text: "Apples of cheeks Style 3",
    //   value: "blush_apple_of_cheek_style2",
    //   image: { avatar: true, src: "/Masks/blush_1.png" },
    // },
    // {
    //   name: "blush_apple_of_cheek_style3",
    //   text: "Apples of cheeks Style 4",
    //   value: "blush_apple_of_cheek_style3",
    //   image: { avatar: true, src: "/Masks/blush_1.png" },
    // },
    {
      name: "blush_2",
      text: "Full cheekbone",
      value: "blush_2",
      image: { avatar: true, src: "/Masks/blush_2.png" },
    },
    // {
    //   name: "blush_lower_cheek_style1",
    //   text: "Full cheekbone Style 2",
    //   value: "blush_lower_cheek_style1",
    //   image: { avatar: true, src: "/Masks/blush_2.png" },
    // },
    // {
    //   name: "blush_lower_cheek_style2",
    //   text: "Full cheekbone Style 3",
    //   value: "blush_lower_cheek_style2",
    //   image: { avatar: true, src: "/Masks/blush_2.png" },
    // },
    // {
    //   name: "blush_lower_cheek_style3",
    //   text: "Full cheekbone Style 4",
    //   value: "blush_lower_cheek_style3",
    //   image: { avatar: true, src: "/Masks/blush_2.png" },
    // },
    {
      name: "blush_3",
      text: "Lower cheeks",
      value: "blush_3",
      image: { avatar: true, src: "/Masks/blush_3.png" },
    },
    // {
    //   name: "blush_lower_cheek_style1",
    //   text: "Lower cheeks Style 2",
    //   value: "blush_lower_cheek_style1",
    //   image: { avatar: true, src: "/Masks/blush_3.png" },
    // },
    // {
    //   name: "blush_lower_cheek_style2",
    //   text: "Lower cheeks Style 3",
    //   value: "blush_lower_cheek_style2",
    //   image: { avatar: true, src: "/Masks/blush_3.png" },
    // },
    // {
    //   name: "blush_lower_cheek_style3",
    //   text: "Lower cheeks Style 4",
    //   value: "blush_lower_cheek_style3",
    //   image: { avatar: true, src: "/Masks/blush_3.png" },
    // },
  ],
  Lipliner: [
    {
      name: "lipliner_1",
      text: "lipliner_1",
      value: "lipliner_1",
      image: { avatar: true, src: "/Masks/lipliner_1.png" },
    },
    {
      name: "lipliner_2",
      text: "lipliner_2",
      value: "lipliner_2",
      image: { avatar: true, src: "/Masks/lipliner_2.png" },
    },
    {
      name: "lipliner_3",
      text: "lipliner_3",
      value: "lipliner_3",
      image: { avatar: true, src: "/Masks/lipliner_3.png" },
    },
  ],
  Highlighter: [
    {
      name: "highlighter_1",
      text: "Highlighter 1",
      value: "highlighter_1",
      image: { avatar: true, src: "/Masks/highlighter_1.png" },
    },
    {
      name: "highlighter_bridge_of_nose",
      text: "Bridge of nose",
      value: "highlighter_bridge_of_nose",
      image: { avatar: true, src: "/Masks/highlighter_1.png" },
    },
    {
      name: "highlighter_full",
      text: "Full",
      value: "highlighter_full",
      image: { avatar: true, src: "/Masks/highlighter_1.png" },
    },
    {
      name: "highlighter_high_cheek_bone",
      text: "High cheek bone",
      value: "highlighter_high_cheek_bone",
      image: { avatar: true, src: "/Masks/highlighter_1.png" },
    },
    {
      name: "highlighter_middle_of_chin",
      text: "Middle of chin",
      value: "highlighter_middle_of_chin",
      image: { avatar: true, src: "/Masks/highlighter_1.png" },
    },
    {
      name: "highlighter_upper_lip_cupids_bow",
      text: "Upper lip cupids bow",
      value: "highlighter_upper_lip_cupids_bow",
      image: { avatar: true, src: "/Masks/highlighter_1.png" },
    },
    {
      name: "highlighter_2",
      text: "Highlighter 2",
      value: "highlighter_2",
      image: { avatar: true, src: "/Masks/highlighter_1.png" },
    },
    {
      name: "highlighter_shimmer",
      text: "Highlighter Shimmer",
      value: "highlighter_shimmer",
      image: { avatar: true, src: "/Masks/highlighter_1.png" },
    },
  ],
  Contour: [
    {
      name: "bronzer_fullface_contour_1",
      text: "Full Face Contour A",
      value: "bronzer_fullface_contour_1",
      image: { avatar: true, src: "/Masks/bronzer_contour.png" },
    },
    {
      name: "bronzer_fullface_contour_2",
      text: "Full Face Contour B",
      value: "bronzer_fullface_contour_2",
      image: { avatar: true, src: "/Masks/bronzer_contour.png" },
    },
  ],
  Bronzer: [
    {
      name: "bronzer_contour",
      text: "Contour",
      value: "bronzer_contour",
      image: { avatar: true, src: "/Masks/bronzer_contour.png" },
    },
    {
      name: "bronzer_fullface",
      text: "Full Face",
      value: "bronzer_fullface",
      image: { avatar: true, src: "/Masks/bronzer_fullface.png" },
    },
    {
      name: "bronzer_sunkiss",
      text: "Sun Kissed",
      value: "bronzer_sunkiss",
      image: { avatar: true, src: "/Masks/bronzer_sunkiss.png" },
    },
  ],
  Eyelash: [
    {
      name: "eyelash_1",
      text: "Thick top lashes",
      value: "eyelash_1",
      image: { avatar: true, src: "/Masks/eyelash_1.png" },
    },
    {
      name: "eyelash_2",
      text: "Long lashes",
      value: "eyelash_2",
      image: { avatar: true, src: "/Masks/eyelash_2.png" },
    },
    {
      name: "eyelash_3",
      text: "Subtle lashes",
      value: "eyelash_3",
      image: { avatar: true, src: "/Masks/eyelash_3.png" },
    },
    {
      name: "eyelash_4",
      text: "Curled lashes",
      value: "eyelash_4",
      image: { avatar: true, src: "/Masks/eyelash_4.png" },
    },
    {
      name: "eyelash_5",
      text: "Defined lashes",
      value: "eyelash_5",
      image: { avatar: true, src: "/Masks/eyelash_5.png" },
    },
    {
      name: "eyelash_6",
      text: "Thick winged lashes",
      value: "eyelash_6",
      image: { avatar: true, src: "/Masks/eyelash_6.png" },
    },
    {
      name: "eyelash_7",
      text: "Thin winged lashes",
      value: "eyelash_7",
      image: { avatar: true, src: "/Masks/eyelash_7.png" },
    },
    {
      name: "eyelash_8",
      text: "Long top lashes",
      value: "eyelash_8",
      image: { avatar: true, src: "/Masks/eyelash_8.png" },
    },
    {
      name: "eyelash_9",
      text: "Mid-length lashes",
      value: "eyelash_9",
      image: { avatar: true, src: "/Masks/eyelash_9.png" },
    },
    {
      name: "eyelash_10",
      text: "Bottom-heavy lashes",
      value: "eyelash_10",
      image: { avatar: true, src: "/Masks/eyelash_10.png" },
    },
    {
      name: "eyelash_11",
      text: "Dramatic lashes",
      value: "eyelash_11",
      image: { avatar: true, src: "/Masks/eyelash_11.png" },
    },
    {
      name: "eyelash_12",
      text: "Long and defined lashes",
      value: "eyelash_12",
      image: { avatar: true, src: "/Masks/eyelash_12.png" },
    },
    {
      name: "eyelash_13",
      text: "Full lashes",
      value: "eyelash_13",
      image: { avatar: true, src: "/Masks/eyelash_13.png" },
    },
  ],
  Eyeshadow: [
    {
      name: "eyeshadow_1",
      text: "Smokey eye",
      value: "eyeshadow_1",
      image: { avatar: true, src: "/Masks/eyeshadow_1.png" },
    },
    {
      name: "eyeshadow_2",
      text: "Smokey top lid",
      value: "eyeshadow_2",
      image: { avatar: true, src: "/Masks/eyeshadow_2.png" },
    },
    {
      name: "eyeshadow_3",
      text: "Thin top and bottom",
      value: "eyeshadow_3",
      image: { avatar: true, src: "/Masks/eyeshadow_3.png" },
    },
    {
      name: "eyeshadow_4",
      text: "Lower lash line",
      value: "eyeshadow_4",
      image: { avatar: true, src: "/Masks/eyeshadow_4.png" },
    },
    {
      name: "eyeshadow_5",
      text: "Reverse cut crease",
      value: "eyeshadow_5",
      image: { avatar: true, src: "/Masks/eyeshadow_5.png" },
    },
    {
      name: "eyeshadow_6",
      text: "Outer eye",
      value: "eyeshadow_6",
      image: { avatar: true, src: "/Masks/eyeshadow_6.png" },
    },
    {
      name: "eyeshadow_7",
      text: "Inner eye",
      value: "eyeshadow_7",
      image: { avatar: true, src: "/Masks/eyeshadow_7.png" },
    },
    {
      name: "eyeshadow_8",
      text: "Thin inner eye",
      value: "eyeshadow_8",
      image: { avatar: true, src: "/Masks/eyeshadow_8.png" },
    },
    {
      name: "eyeshadow_9",
      text: "Inner lower eye",
      value: "eyeshadow_9",
      image: { avatar: true, src: "/Masks/eyeshadow_9.png" },
    },
    {
      name: "eyeshadow_10",
      text: "Smudgy smokey top lid",
      value: "eyeshadow_10",
      image: { avatar: true, src: "/Masks/eyeshadow_10.png" },
    },
    {
      name: "eyeshadow_11",
      text: "Winged lid",
      value: "eyeshadow_11",
      image: { avatar: true, src: "/Masks/eyeshadow_11.png" },
    },
    {
      name: "eyeshadow_12",
      text: "Round smokey eye",
      value: "eyeshadow_12",
      image: { avatar: true, src: "/Masks/eyeshadow_12.png" },
    },
    {
      name: "eyeshadow_13",
      text: "Half cut crease",
      value: "eyeshadow_13",
      image: { avatar: true, src: "/Masks/eyeshadow_13.png" },
    },
    {
      name: "eyeshadow_14",
      text: "Under eye shadow",
      value: "eyeshadow_14",
      image: { avatar: true, src: "/Masks/eyeshadow_14.png" },
    },
    {
      name: "eyeshadow_15",
      text: "Light wash",
      value: "eyeshadow_15",
      image: { avatar: true, src: "/Masks/eyeshadow_15.png" },
    },
    {
      name: "eyeshadow_16",
      text: "Dramatic outer eye",
      value: "eyeshadow_16",
      image: { avatar: true, src: "/Masks/eyeshadow_16.png" },
    },
    {
      name: "eyeshadow_17",
      text: "Ombre wing",
      value: "eyeshadow_17",
      image: { avatar: true, src: "/Masks/eyeshadow_17.png" },
    },
    {
      name: "eyeshadow_18",
      text: "Dramatic inner eye",
      value: "eyeshadow_18",
      image: { avatar: true, src: "/Masks/eyeshadow_18.png" },
    },
    {
      name: "eyeshadow_19",
      text: "Smokey eye II",
      value: "eyeshadow_19",
      image: { avatar: true, src: "/Masks/eyeshadow_19.png" },
    },
    {
      name: "eyeshadow_20",
      text: "Smokey almond",
      value: "eyeshadow_20",
      image: { avatar: true, src: "/Masks/eyeshadow_20.png" },
    },
    {
      name: "eyeshadow_21",
      text: "Intense top lid",
      value: "eyeshadow_21",
      image: { avatar: true, src: "/Masks/eyeshadow_21.png" },
    },
    {
      name: "eyeshadow_22",
      text: "Intense inner eye",
      value: "eyeshadow_22",
      image: { avatar: true, src: "/Masks/eyeshadow_22.png" },
    },
    {
      name: "eyeshadow_23",
      text: "Winged lower lash line",
      value: "eyeshadow_23",
      image: { avatar: true, src: "/Masks/eyeshadow_23.png" },
    },
    {
      name: "eyeshadow_24",
      text: "Thin inner eye",
      value: "eyeshadow_24",
      image: { avatar: true, src: "/Masks/eyeshadow_24.png" },
    },
    {
      name: "eyeshadow_25",
      text: "Smokey almond II",
      value: "eyeshadow_25",
      image: { avatar: true, src: "/Masks/eyeshadow_25.png" },
    },
    {
      name: "eyeshadow_26",
      text: "Thin inner eye II",
      value: "eyeshadow_26",
      image: { avatar: true, src: "/Masks/eyeshadow_26.png" },
    },
    {
      name: "eyeshadow_27",
      text: "Brow Bone",
      value: "eyeshadow_27",
      image: { avatar: true, src: "/Masks/eyeshadow_26.png" },
    },
    {
      name: "eyeshadow_28",
      text: "Upper Lid",
      value: "eyeshadow_28",
      image: { avatar: true, src: "/Masks/eyeshadow_26.png" },
    },
    {
      name: "eyeshadow_29",
      text: "Liner Shadow",
      value: "eyeshadow_29",
      image: { avatar: true, src: "/Masks/eyeshadow_26.png" },
    },
    {
      name: "eyeshadow_30",
      text: "Crease",
      value: "eyeshadow_30",
      image: { avatar: true, src: "/Masks/eyeshadow_26.png" },
    },

  ],
  Eyeliner: [
    {
      name: "eyeliner_1",
      text: "Simple",
      value: "eyeliner_1",
      image: { avatar: true, src: "/Masks/eyeliner_1.png" },
    },
    {
      name: "eyeliner_2",
      text: "Subtle wing",
      value: "eyeliner_2",
      image: { avatar: true, src: "/Masks/eyeliner_2.png" },
    },
    {
      name: "eyeliner_3",
      text: "Natural smudge",
      value: "eyeliner_3",
      image: { avatar: true, src: "/Masks/eyeliner_3.png" },
    },
    {
      name: "eyeliner_4",
      text: "Smudgy cat eye",
      value: "eyeliner_4",
      image: { avatar: true, src: "/Masks/eyeliner_4.png" },
    },
    {
      name: "eyeliner_5",
      text: "Thin",
      value: "eyeliner_5",
      image: { avatar: true, src: "/Masks/eyeliner_5.png" },
    },
    {
      name: "eyeliner_6",
      text: "Full cat eye",
      value: "eyeliner_6",
      image: { avatar: true, src: "/Masks/eyeliner_6.png" },
    },
    {
      name: "eyeliner_7",
      text: "Thin subtle wing",
      value: "eyeliner_7",
      image: { avatar: true, src: "/Masks/eyeliner_7.png" },
    },
    {
      name: "eyeliner_8",
      text: "Dramatic cat eye",
      value: "eyeliner_8",
      image: { avatar: true, src: "/Masks/eyeliner_8.png" },
    },
    {
      name: "eyeliner_9",
      text: "Thick subtle wing",
      value: "eyeliner_9",
      image: { avatar: true, src: "/Masks/eyeliner_9.png" },
    },
    {
      name: "eyeliner_10",
      text: "Full top and bottom",
      value: "eyeliner_10",
      image: { avatar: true, src: "/Masks/eyeliner_10.png" },
    },
    {
      name: "eyeliner_11",
      text: "Very thin",
      value: "eyeliner_11",
      image: { avatar: true, src: "/Masks/eyeliner_11.png" },
    },
  ],
  Eyebrow: [
    {
      name: "eyebrow_powder",
      text: "Powder A",
      value: "eyebrow_powder",
      image: { avatar: true, src: "/Masks/eyebrow_powder.png" },
    },
    {
      name: "eyebrow_powder_3",
      text: "Powder B",
      value: "eyebrow_powder_3",
      image: { avatar: true, src: "/Masks/eyebrow_powder_3.png" },
    },
    {
      name: "eyebrow_powder_4",
      text: "Powder C",
      value: "eyebrow_powder_4",
      image: { avatar: true, src: "/Masks/eyebrow_powder_4.png" },
    },
    {
      name: "eyebrow_powder_5",
      text: "Powder D",
      value: "eyebrow_powder_5",
      image: { avatar: true, src: "/Masks/eyebrow_powder_5.png" },
    },
    {
      name: "eyebrow_creme_lowerUpper_1",
      text: "Upper N' Lower A",
      value: "eyebrow_creme_lowerUpper_1",
      image: { avatar: true, src: "/Masks/eyebrow_creme_lowerUpper_1.png" },
    },
    {
      name: "eyebrow_creme_lowerUpper_2",
      text: "Upper N' Lower B",
      value: "eyebrow_creme_lowerUpper_2",
      image: { avatar: true, src: "/Masks/eyebrow_creme_lowerUpper_2.png" },
    },
  ],
};

const GlitterMasks = {
  Lipstick: [
    {
      name: "lipGlitter_2",
      text: "lipGlitter_1",
      value: "lipGlitter_2",
      image: { avatar: true, src: "/Masks/lipGlitter_2.png" },
    },
  ],
};

const ShimmerMasks = {
  Lipstick: [
    {
      name: "lipShimmer_1",
      text: "lipShimmer_1",
      value: "lipShimmer_1",
      image: { avatar: true, src: "/Masks/lipShimmer_1.png" },
    },
  ],
  Blush: [
    {
      name: "blush_shimmer_1",
      text: "Apple of cheeks",
      value: "blush_shimmer_1",
      image: { avatar: true, src: "/Masks/blush_1.png" },
    },
    // {
    //   name: "blush_shimmer_apple_of_cheek_style1",
    //   text: "Apple of cheeks Style 2",
    //   value: "blush_shimmer_apple_of_cheek_style1",
    //   image: { avatar: true, src: "/Masks/blush_shimmer.png" },
    // },
    // {
    //   name: "blush_shimmer_apple_of_cheek_style2",
    //   text: "Apple of cheeks Style 3",
    //   value: "blush_shimmer_apple_of_cheek_style2",
    //   image: { avatar: true, src: "/Masks/blush_shimmer.png" },
    // },
    // {
    //   name: "blush_shimmer_apple_of_cheek_style3",
    //   text: "Apple of cheeks Style 4",
    //   value: "blush_shimmer_apple_of_cheek_style3",
    //   image: { avatar: true, src: "/Masks/blush_shimmer.png" },
    // },

    {
      name: "blush_shimmer_2",
      text: "Full cheek bone",
      value: "blush_shimmer_2",
      image: { avatar: true, src: "/Masks/blush_2.png" },
    },
    // {
    //   name: "blush_shimmer_full_cheekbone_style1",
    //   text: "Full cheek bone Style 2",
    //   value: "blush_shimmer_full_cheekbone_style1",
    //   image: { avatar: true, src: "/Masks/blush_shimmer.png" },
    // },
    // {
    //   name: "blush_shimmer_full_cheekbone_style2",
    //   text: "Full cheek bone Style 3",
    //   value: "blush_shimmer_full_cheekbone_style2",
    //   image: { avatar: true, src: "/Masks/blush_shimmer.png" },
    // },
    // {
    //   name: "blush_shimmer_full_cheekbone_style3",
    //   text: "Full cheek bone Style 4",
    //   value: "blush_shimmer_full_cheekbone_style3",
    //   image: { avatar: true, src: "/Masks/blush_shimmer.png" },
    // },


    {
      name: "blush_shimmer_3",
      text: "Lower cheek",
      value: "blush_shimmer_3",
      image: { avatar: true, src: "/Masks/blush_3.png" },
    },
    // {
    //   name: "blush_shimmer_lower_cheek_style1",
    //   text: "Lower cheek Style 2",
    //   value: "blush_shimmer_lower_cheek_style1",
    //   image: { avatar: true, src: "/Masks/blush_shimmer.png" },
    // },
    // {
    //   name: "blush_shimmer_lower_cheek_style2",
    //   text: "Lower cheek Style 3",
    //   value: "blush_shimmer_lower_cheek_style2",
    //   image: { avatar: true, src: "/Masks/blush_shimmer.png" },
    // },
    // {
    //   name: "blush_shimmer_lower_cheek_style3",
    //   text: "Lower cheek Style 4",
    //   value: "blush_shimmer_lower_cheek_style3",
    //   image: { avatar: true, src: "/Masks/blush_shimmer.png" },
    // },

  ],
  Highlighter: [
    {
      name: "highlighter_shimmer",
      text: "highlighter_shimmer",
      value: "highlighter_shimmer",
      image: { avatar: true, src: "/Masks/highlighter_shimmer.png" },
    },
  ],
  Eyeshadow: [
    {
      name: "eyeshadow_shimmer_1",
      text: "Smokey eye",
      value: "eyeshadow_shimmer_1",
      image: { avatar: true, src: "/Masks/eyeshadow_1.png" },
    },
    {
      name: "eyeshadow_shimmer_2",
      text: "Smokey top lid",
      value: "eyeshadow_shimmer_2",
      image: { avatar: true, src: "/Masks/eyeshadow_2.png" },
    },
    {
      name: "eyeshadow_shimmer_3",
      text: "Thin top and bottom",
      value: "eyeshadow_shimmer_3",
      image: { avatar: true, src: "/Masks/eyeshadow_3.png" },
    },
    {
      name: "eyeshadow_shimmer_4",
      text: "Lower lash line",
      value: "eyeshadow_shimmer_4",
      image: { avatar: true, src: "/Masks/eyeshadow_4.png" },
    },
    {
      name: "eyeshadow_shimmer_5",
      text: "Reverse cut crease",
      value: "eyeshadow_shimmer_5",
      image: { avatar: true, src: "/Masks/eyeshadow_5.png" },
    },
    {
      name: "eyeshadow_shimmer_6",
      text: "Outer eye",
      value: "eyeshadow_shimmer_6",
      image: { avatar: true, src: "/Masks/eyeshadow_6.png" },
    },
    {
      name: "eyeshadow_shimmer_7",
      text: "Inner eye",
      value: "eyeshadow_shimmer_7",
      image: { avatar: true, src: "/Masks/eyeshadow_7.png" },
    },
    {
      name: "eyeshadow_shimmer_8",
      text: "Thin inner eye",
      value: "eyeshadow_shimmer_8",
      image: { avatar: true, src: "/Masks/eyeshadow_8.png" },
    },
    {
      name: "eyeshadow_shimmer_9",
      text: "Inner lower eye",
      value: "eyeshadow_shimmer_9",
      image: { avatar: true, src: "/Masks/eyeshadow_9.png" },
    },
    {
      name: "eyeshadow_shimmer_10",
      text: "Smudgy smokey top lid",
      value: "eyeshadow_shimmer_10",
      image: { avatar: true, src: "/Masks/eyeshadow_10.png" },
    },
    {
      name: "eyeshadow_shimmer_11",
      text: "Winged lid ",
      value: "eyeshadow_shimmer_11",
      image: { avatar: true, src: "/Masks/eyeshadow_11.png" },
    },
    {
      name: "eyeshadow_shimmer_12",
      text: "Round smokey eye",
      value: "eyeshadow_shimmer_12",
      image: { avatar: true, src: "/Masks/eyeshadow_12.png" },
    },
    {
      name: "eyeshadow_shimmer_13",
      text: "Half cut crease",
      value: "eyeshadow_shimmer_13",
      image: { avatar: true, src: "/Masks/eyeshadow_13.png" },
    },
    {
      name: "eyeshadow_shimmer_14",
      text: "Under eye shadow",
      value: "eyeshadow_shimmer_14",
      image: { avatar: true, src: "/Masks/eyeshadow_14.png" },
    },
    {
      name: "eyeshadow_shimmer_15",
      text: "Light wash",
      value: "eyeshadow_shimmer_15",
      image: { avatar: true, src: "/Masks/eyeshadow_15.png" },
    },
    {
      name: "eyeshadow_shimmer_16",
      text: "Dramatic outer eye",
      value: "eyeshadow_shimmer_16",
      image: { avatar: true, src: "/Masks/eyeshadow_16.png" },
    },
    {
      name: "eyeshadow_shimmer_17",
      text: "Ombre wing",
      value: "eyeshadow_shimmer_17",
      image: { avatar: true, src: "/Masks/eyeshadow_17.png" },
    },
    {
      name: "eyeshadow_shimmer_18",
      text: "Dramatic inner eye",
      value: "eyeshadow_shimmer_18",
      image: { avatar: true, src: "/Masks/eyeshadow_18.png" },
    },
    {
      name: "eyeshadow_shimmer_19",
      text: "Smokey eye II",
      value: "eyeshadow_shimmer_19",
      image: { avatar: true, src: "/Masks/eyeshadow_19.png" },
    },
    {
      name: "eyeshadow_shimmer_20",
      text: "Smokey almond",
      value: "eyeshadow_shimmer_20",
      image: { avatar: true, src: "/Masks/eyeshadow_20.png" },
    },
    {
      name: "eyeshadow_shimmer_21",
      text: "Intense top lid",
      value: "eyeshadow_shimmer_21",
      image: { avatar: true, src: "/Masks/eyeshadow_21.png" },
    },
    {
      name: "eyeshadow_shimmer_22",
      text: "Intense inner eye",
      value: "eyeshadow_shimmer_22",
      image: { avatar: true, src: "/Masks/eyeshadow_22.png" },
    },
    {
      name: "eyeshadow_shimmer_23",
      text: "Winged lower lash line",
      value: "eyeshadow_shimmer_23",
      image: { avatar: true, src: "/Masks/eyeshadow_23.png" },
    },
    {
      name: "eyeshadow_shimmer_24",
      text: "Thin inner eye",
      value: "eyeshadow_shimmer_24",
      image: { avatar: true, src: "/Masks/eyeshadow_24.png" },
    },
    {
      name: "eyeshadow_shimmer_25",
      text: "Smokey almond II",
      value: "eyeshadow_shimmer_25",
      image: { avatar: true, src: "/Masks/eyeshadow_25.png" },
    },
    {
      name: "eyeshadow_shimmer_26",
      text: "Thin inner eye II",
      value: "eyeshadow_shimmer_26",
      image: { avatar: true, src: "/Masks/eyeshadow_26.png" },
    },
    {
      name: "eyeshadow_shimmer_27",
      text: "Brow Bone",
      value: "eyeshadow_shimmer_27",
      image: { avatar: true, src: "/Masks/eyeshadow_26.png" },
    },
    {
      name: "eyeshadow_shimmer_28",
      text: "Upper Lid",
      value: "eyeshadow_shimmer_28",
      image: { avatar: true, src: "/Masks/eyeshadow_26.png" },
    },
    {
      name: "eyeshadow_shimmer_29",
      text: "Liner Shadow",
      value: "eyeshadow_shimmer_29",
      image: { avatar: true, src: "/Masks/eyeshadow_26.png" },
    },
    {
      name: "eyeshadow_shimmer_30",
      text: "Crease",
      value: "eyeshadow_shimmer_30",
      image: { avatar: true, src: "/Masks/eyeshadow_26.png" },
    },
  ],
};

/** for ordering */
Masks["Blush"].sort((a, b) => (a.text > b.text ? 1 : -1));
Masks["Highlighter"].sort((a, b) => (a.text > b.text ? 1 : -1));
Masks["Bronzer"].sort((a, b) => (a.text > b.text ? 1 : -1));
Masks["Contour"].sort((a, b) => (a.text > b.text ? 1 : -1));
Masks["Lipliner"].sort((a, b) => (a.text > b.text ? 1 : -1));
Masks["Eyelash"].sort((a, b) => (a.text > b.text ? 1 : -1));
Masks["Eyeshadow"].sort((a, b) => (a.text > b.text ? 1 : -1));
Masks["Eyeliner"].sort((a, b) => (a.text > b.text ? 1 : -1));
Masks["Eyebrow"].sort((a, b) => (a.text > b.text ? 1 : -1));

const LipEffects = [
  { name: "Matte", text: "Matte", value: 0 },
  { name: "Satin", text: "Satin", value: 1 },
  { name: "Gloss", text: "Gloss", value: 2 },
];
const FoundationEffects = [
  { name: "Matte", text: "Matte", value: "Matte" },
  { name: "Dewy", text: "Dewy", value: "Dewy" },
  { name: "Dewy 1", text: "Dewy 1", value: "Dewy_1" },
  { name: "Dewy 2", text: "Dewy 2", value: "Dewy_2" },
  { name: "Dewy 3", text: "Dewy 3", value: "Dewy_3" },
  { name: "Glossy", text: "Glossy", value: "Glossy" },
  { name: "Full Mask", text: "Full Mask", value: "Full_Mask" },
  { name: "Full Mask 2", text: "Full Mask 2", value: "Full_Mask_2" },
  
];

const InitialColors = {
  foundation: [241, 193, 171, 0.225],
  "foundation 2": [241, 193, 171, 0.225],
  blender: [241, 193, 171, 0.225],
  lipstick: [220, 86, 86, 0.5],
  blush: [217, 128, 117, 0.4],
  lipliner: [90, 45, 34, 0.5],
  highlighter: [217, 209, 117, 0.4],
  bronzer: [195, 147, 101, 0.5],
  contour: [228, 211, 182, 0.4],
  eyelash: [25, 25, 25, 0.35],
  eyeshadow: [81, 32, 60, 0.6],
  eyeliner: [33, 35, 35, 0.45],
  eyebrow: [167, 133, 106, 0.5],
};

const initialState = {
  selectedMask: undefined,
  skuName: undefined,
  skuId: undefined,

  lipEffectValue: undefined,
  lipEffectType: undefined,

  foundationEffectValue: undefined,
  foundationEffectType: undefined,
  foundationSmoothingCoefficentValue: undefined,
  foundationShininessValue: undefined,
  foundationBlendingRatioValue: undefined,
  foundationMaskIntensityValue: undefined,

  selectedGlitterMask: undefined,
  glitterIntensityValue: 0,

  selectedShimmerMask: undefined,
  shimmerIntensityValue: 0,

  r: -1,
  g: -1,
  b: -1,
  a: -1,

  shimmer_r: -1,
  shimmer_g: -1,
  shimmer_b: -1,
  shimmer_a: -1,
  isColorPickerActive: false,
  isProductEditColorPickerActive: false,
  isColorFeatureEditPickerActive: false,
  isColorFeatureAddPickerActive: false,
  showMask: false,
  showLipEffectType: false,
  showLipEffectIntensity: false,
  activeView: undefined,
  activeEdit: {
    idx: undefined,
    skuId: undefined,
    skuName: undefined,
    selectedMask: undefined,
    lipEffectType: undefined,
    foundationEffectType: undefined,
    lipEffectValue: undefined,
    foundationEffectValue: undefined,
    foundationSmoothingCoefficentValue: undefined,
    foundationShininessCoefficentValue: undefined,
    foundationBlendingRatioCoefficentValue: undefined,
    foundationMaskIntensityCoefficentValue: undefined,
    selectedGlitterMask: undefined,
    glitterIntensityValue: undefined,
    selectedShimmerMask: undefined,
    shimmerIntensityValue: undefined,
  },
  activeDelete: undefined,
  showFoundationEffectType: false,
  showFoundationIntensity: false,
  showFoundationSmoothingCoefficent: false,
  showFoundationShininess: false,
  showFoundationBlendingRatio: false,
  showFoundationMaskIntensity: false,
  showBlushShimmerColor: false,
  showEyeshadowShimmerColor: false,
};

const DoubleClickButtonInitialState = {
  isDoubleClickActive: false,
  cur_sec: 5,
};
class DoubleClickButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = DoubleClickButtonInitialState;
    this.stepOneHandle = this.stepOneHandle.bind(this);
  }
  stepOneHandle() {
    var self = this;
    if (this.state.isDoubleClickActive) {
      ProductStore.removeProduct(
        this.props.toRemove.sku_id,
        this.props.toRemove.type,
        this.props.toRemove.prd_idx,
        this.props.toRemove.idx
      );
      window.SERVICES.notify(
        `${this.props.toRemove.sku_name} (product) removed`,
        "WARN"
      );
      this.setState(DoubleClickButtonInitialState);
      clearInterval(self.interval);
    } else {
      this.setState({ isDoubleClickActive: true });
      this.interval = setInterval(function () {
        if (self.state.cur_sec == 0) {
          self.setState(DoubleClickButtonInitialState);
          clearInterval(self.interval);
        } else {
          self.setState({ cur_sec: self.state.cur_sec - 1 });
        }
      }, 1000);
    }
  }

  render() {
    return (
      <Button
        size="mini"
        icon="close"
        content={
          this.state.isDoubleClickActive
            ? `Sure? (${this.state.cur_sec}s)`
            : "Delete"
        }
        color={this.state.isDoubleClickActive ? "red" : ""}
        onClick={this.stepOneHandle}
      />
    );
  }
}

export class DynamicTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.showColorPicker = this.showColorPicker.bind(this);
    this.showProductEditColorPicker = this.showProductEditColorPicker.bind(this);
    this.showFeatureAddColorPicker = this.showFeatureAddColorPicker.bind(this);
    this.showFeatureEditColorPicker = this.showFeatureEditColorPicker.bind(this);
    this.addProduct = this.addProduct.bind(this);
    this.clearRender = this.clearRender.bind(this);
    this.applyMakeup = this.applyMakeup.bind(this);
    this.applyMakeupFromState = this.applyMakeupFromState.bind(this);
    this.handleMakeupToggle = this.handleMakeupToggle.bind(this);
    this.returnComponentColor = this.returnComponentColor.bind(this);
    this.getBackgroundColor = this.getBackgroundColor.bind(this);
    this.getBackgroundColorForBlushShimmer = this.getBackgroundColorForBlushShimmer.bind(this);
    this.getBackgroundColorForEyeshadowShimmer = this.getBackgroundColorForEyeshadowShimmer.bind(this);
    this.getDefaultMask = this.getDefaultMask.bind(this);
    this.getIndex = this.getIndex.bind(this);

    this.maskDropdownRef = React.createRef();
    this.glitterMaskDropdownRef = React.createRef();
    this.glitterIntensityRef = React.createRef();
    this.shimmerMaskDropdownRef = React.createRef();
    this.shimmerIntensityRef = React.createRef();
    this.leDropdownRef = React.createRef();
    this.leIntensityRef = React.createRef();

    this.foDropdownRef = React.createRef();
    this.foIntensityRef = React.createRef();
    this.foSmooothingCoefficentRef = React.createRef();
    this.foShininessRef = React.createRef();
    this.foBlendingRatioRef = React.createRef();
    this.foMaskIntensityRef = React.createRef();
  }

  getIndex() {
    return ProductStore.getProductListLenByType(
      this.props.itemTitle.toLowerCase()
    );
  }

  handleMakeupToggle(item) {
    var cur_view_btn = `${item.idx}+_${item.skuId}`;
    if (this.state.activeView == cur_view_btn) {
      /** remove that particular makeup */
      if (item.type == "FOUNDATION") window.vmt_module._resetFoundation();
      if (item.type == "FOUNDATION 2") window.vmt_module._resetFoundation_2();
      if (item.type == "BLENDER") window.vmt_module._resetBlender();
      if (item.type == "LIPSTICK") window.vmt_module._resetLipstick();
      if (item.type == "BLUSH") window.vmt_module._resetBlush();
      if (item.type == "LIPLINER") window.vmt_module._resetLipliner();
      if (item.type == "HIGHLIGHTER") window.vmt_module._resetHighlighter();
      if (item.type == "BRONZER") window.vmt_module._resetBronzer();
      if (item.type == "CONTOUR") window.vmt_module._resetContour();
      if (item.type == "EYESHADOW") window.vmt_module._resetEyeshadow();
      if (item.type == "EYELINER") window.vmt_module._resetEyeliner();
      if (item.type == "EYEBROW") window.vmt_module._resetEyebrow();
      if (item.type == "EYELASH") window.vmt_module._resetEyelash();

      if (!(AppStore.currentPage == AppStore.PagesEnum.LIVE)) {
        if (
          !(AppStore.currentPage == AppStore.PagesEnum.MAIN) &&
          AppStore.currentPage == AppStore.PagesEnum.MODEL &&
          AppStore.currentApp == AppStore.AppsEnum.ARTIST
        ) {
          Object.keys(window.SERVICES.vmtCoreCollection).forEach((i) => {
            window.vmt_module._setModelImageBypass(i);
            window.SERVICES.vmtCoreCollection[i].resetDetection();
            window.SERVICES.vmtCoreCollection[i].getDetection();
            window.SERVICES.vmtCoreCollection[i].render();
          });
        } else {
          window.SERVICES.vmtcore.render();
        }
      }
      this.setState({ activeView: undefined });
    } else {
      /** apply makeup */
      for (var i=0; i<item["product_features"].length; i++) {
        this.applyMakeup(item["product_features"][i], false);
      }

      this.setState({ activeView: cur_view_btn });
    }
  }

  clearRender() {
    window.vmt_module._resetAllMakeup();

    var activeEdit = {
      idx: this.state.activeEdit.idx,
      skuId: this.state.activeEdit.skuId,
      a: 0,
      b: 0,
      foundationEffectType: 0,
      foundationEffectValue: 0,
      foundationSmoothingCoefficentValue: 0,
      foundationShininessValue: 0,
      foundationBlendingRatioValue: 0,
      foundationMaskIntensityValue: 0,
      g: 0,
      glitterIntensityValue: 0,
      lipEffectType: 0,
      lipEffectValue: 0,
      r: 0,
      selectedGlitterMask: 0,
      selectedMask: 0,
      selectedShimmerMask: 0,
      shimmerIntensityValue: 0,
      skuName: "",
      shimmer_r:0,
      shimmer_g:0,
      shimmer_b:0,
      shimmer_a:0
    };

    this.setState({ activeView: undefined, activeEdit });
    if (!(AppStore.currentPage == AppStore.PagesEnum.LIVE)) {
      if (
        !(AppStore.currentPage == AppStore.PagesEnum.MAIN) &&
        AppStore.currentPage == AppStore.PagesEnum.MODEL &&
        AppStore.currentApp == AppStore.AppsEnum.ARTIST
      ) {
        Object.keys(window.SERVICES.vmtCoreCollection).forEach((i) => {
          window.vmt_module._setModelImageBypass(i);
          window.SERVICES.vmtCoreCollection[i].resetDetection();
          window.SERVICES.vmtCoreCollection[i].getDetection();
          window.SERVICES.vmtCoreCollection[i].render();
        });
      } else {
        window.SERVICES.vmtcore.render();
      }
    }
  }

  cloneProduct = item => {
    ProductStore.cloneProduct(item.idx, this.props.itemTitle.toUpperCase());
  }

  addFeatureToProduct = item => {
    let skuname = this.state.skuName;
    let skuid = this.state.skuId;
    let mask = undefined;
    let lipeffect_type = undefined;
    let foundationeffect_type = undefined;
    let lipeffect_val = undefined;
    let foundationeffect_val = undefined;
    let glitter_mask = undefined;
    let glitter_intensity = undefined;
    let shimmer_mask = undefined;
    let shimmer_intensity = undefined;
    let foundation_smoothing_coefficent = undefined;
    let foundation_shininess = undefined;
    let foundation_blending_ratio = undefined;
    let foundation_mask_intensity = undefined;

    if (
      this.state.skuName === "" ||
      this.state.skuName === undefined ||
      this.state.skuName === null
    ) {
      skuname = this.props.itemTitle.toUpperCase() + "_" + this.getIndex();
    }

    if (
      this.state.skuId === "" ||
      this.state.skuId === undefined ||
      this.state.skuId === null
    ) {
      skuid = this.props.itemTitle.toUpperCase() + "_" + this.getIndex();
    }

    if (this.state.showMask) {
      if (this.state.selectedMask === undefined) {
        mask = this.getDefaultMask();
      } else {
        mask = this.state.selectedMask;
      }
    }

    if (this.state.showLipEffectType) {
      if (this.state.lipEffectType === undefined) {
        lipeffect_type = 0;
      } else {
        lipeffect_type = this.state.lipEffectType;
      }
    }
    if (this.state.showFoundationEffectType) {
      if (this.state.foundationEffectType === undefined) {
        lipeffect_type = this.getDefaultFoundationFinishType();
      } else {
        lipeffect_type = this.state.foundationEffectType;
      }
    }

    if (this.state.showLipEffectIntensity) {
      if (this.state.lipEffectValue === undefined) {
        lipeffect_val = 1.0;
      } else {
        lipeffect_val = this.state.lipEffectValue / 100;
      }
    }
    if (this.state.showFoundationIntensity) {
      if (this.state.foundationEffectValue === undefined) {
        lipeffect_val = 0.5;
      } else {
        lipeffect_val = this.state.foundationEffectValue / 100;
      }
    }

    if (this.state.showFoundationSmoothingCoefficent) {
      if (this.state.foundationSmoothingCoefficentValue === undefined) {
        foundation_smoothing_coefficent = 0.5;
      } else {
        foundation_smoothing_coefficent =
          this.state.foundationSmoothingCoefficentValue / 100;
      }
    }
    if (this.state.showFoundationShininess) {
      if (this.state.foundationShininessValue === undefined) {
        foundation_shininess = 0.5;
      } else {
        foundation_shininess = this.state.foundationShininessValue / 100;
      }
    }
    if (this.state.showFoundationBlendingRatio) {
      if (this.state.foundationBlendingRatioValue === undefined) {
        foundation_blending_ratio = 0.5;
      } else {
        foundation_blending_ratio = this.state.foundationBlendingRatioValue / 100;
      }
    }
    if (this.state.showFoundationMaskIntensity) {
      if (this.state.foundationMaskIntensityValue === undefined) {
        foundation_mask_intensity = 0.5;
      } else {
        foundation_mask_intensity = this.state.foundationMaskIntensityValue / 100;
      }
    }

    if (Object.keys(GlitterMasks).indexOf(this.props.itemTitle) != -1) {
      if (this.state.selectedGlitterMask === undefined) {
        glitter_mask = this.getDefaultGlitterMask();
      } else {
        glitter_mask = this.state.selectedGlitterMask;
      }

      glitter_intensity = this.state.glitterIntensityValue / 100;
    }
    if (Object.keys(ShimmerMasks).indexOf(this.props.itemTitle) != -1) {
      if (this.state.selectedShimmerMask === undefined) {
        shimmer_mask = this.getDefaultShimmerMask();
      } else {
        shimmer_mask = this.state.selectedShimmerMask;
      }

      shimmer_intensity = this.state.shimmerIntensityValue / 100;
    }

    let r = this.state.r;
    let g = this.state.g;
    let b = this.state.b;
    let a = this.state.a;

    if (r === -1 || g === -1 || b === -1 || a === -1) {
      r = InitialColors[this.props.itemTitle.toLowerCase()][0];
      g = InitialColors[this.props.itemTitle.toLowerCase()][1];
      b = InitialColors[this.props.itemTitle.toLowerCase()][2];
      a = InitialColors[this.props.itemTitle.toLowerCase()][3];
    }
    let shimmer_r = this.state.shimmer_r;
    let shimmer_g = this.state.shimmer_g;
    let shimmer_b = this.state.shimmer_b;
    let shimmer_a = this.state.shimmer_a;
    

    if (shimmer_r === -1 || shimmer_g === -1 || shimmer_b === -1 || shimmer_a === -1 ) {
      shimmer_r = InitialColors[this.props.itemTitle.toLowerCase()][0];
      shimmer_g = InitialColors[this.props.itemTitle.toLowerCase()][1];
      shimmer_b = InitialColors[this.props.itemTitle.toLowerCase()][2];
      shimmer_a = InitialColors[this.props.itemTitle.toLowerCase()][3];
//      a = InitialColors[this.props.itemTitle.toLowerCase()][3];
    }

    ProductStore.addProductFeature(
      item.idx,
      skuname,
      this.props.itemTitle.toUpperCase(),
      r,
      g,
      b,
      a,
      lipeffect_val,
      lipeffect_type,
      glitter_mask,
      glitter_intensity,
      shimmer_mask,
      shimmer_intensity,
      mask,
      foundation_smoothing_coefficent,
      foundation_shininess,
      foundation_blending_ratio,
      foundation_mask_intensity,
      shimmer_r,
      shimmer_g,
      shimmer_b,
      shimmer_a
    );

    // reset ui
    $(".sku-id-input > input").val("");
    $(".sku-name-input > input").val("");
    if (this.maskDropdownRef && this.maskDropdownRef.current)
      this.maskDropdownRef.current.state.value = 0;
    if (this.leIntensityRef && this.leIntensityRef.current)
      this.leDropdownRef.current.state.value = 0;
    if (this.leIntensityRef && this.leIntensityRef.current)
      this.leIntensityRef.current.state.value = 100;

    if (this.foIntensityRef && this.foIntensityRef.current)
      this.foDropdownRef.current.state.value =
        this.getDefaultFoundationFinishType();
    if (this.foIntensityRef && this.foIntensityRef.current)
      this.foIntensityRef.current.state.value = 100;
    if (
      this.foSmooothingCoefficentRef &&
      this.foSmooothingCoefficentRef.current
    )
      this.foSmooothingCoefficentRef.current.state.value = 100;

      if (
        this.foShininessRef &&
        this.foShininessRef.current
      )
        this.foShininessRef.current.state.value = 100;
        if (
          this.foBlendingRatioRef &&
          this.foBlendingRatioRef.current
        )
          this.foBlendingRatioRef.current.state.value = 100;
        if (
          this.foMaskIntensityRef &&
          this.foMaskIntensityRef.current
        )
          this.foMaskIntensityRef.current.state.value = 100;

    if (this.glitterMaskDropdownRef && this.glitterMaskDropdownRef.current)
      this.glitterMaskDropdownRef.current.state.value = 0;
    if (this.glitterIntensityRef && this.glitterIntensityRef.current)
      this.glitterIntensityRef.current.state.value = 0;
    if (this.shimmerMaskDropdownRef && this.shimmerMaskDropdownRef.current)
      this.shimmerMaskDropdownRef.current.state.value = 0;
    if (this.shimmerIntensityRef && this.shimmerIntensityRef.current)
      this.shimmerIntensityRef.current.state.value = 0;

    // reset state
    this.setState({
      selectedMask: undefined,
      skuName: undefined,
      skuId: undefined,
      lipEffectValue: 100,
      lipEffectType: undefined,
      foundationEffectType: undefined,
      foundationEffectValue: 100,
      foundationSmoothingCoefficentValue: 100,
      selectedGlitterMask: undefined,
      glitterIntensityValue: 0,
      selectedShimmerMask: undefined,
      shimmerIntensityValue: 0,
      r: -1,
      g: -1,
      b: -1,
      a: -1,
      isColorPickerActive: false,
      isProductEditColorPickerActive: false,
      isColorFeatureEditPickerActive: false,
      isColorFeatureAddPickerActive: false,
      shimmer_r: -1,
      shimmer_g: -1,
      shimmer_b: -1,
      shimmer_a: -1,
    });
  }

  editProductFeature = (prd_idx, item) => {
    var activeEdit = {
      prd_idx,
      idx: item.idx,
      skuId: item.sku_id,
      skuName: item.sku_name,
      selectedMask: item.mask,
      lipEffectType: item.finish_type,
      foundationEffectType: item.finish_type,
      lipEffectValue: item.finish_intensity * 100,
      foundationEffectValue: item.finish_intensity * 100,
      foundationSmoothingCoefficentValue: item.smoothing_coefficent * 100,
      foundationShininessValue: item.shininess * 100,
      foundationBlendingRatioValue: item.blending_ratio * 100,
      foundationMaskIntensityValue: item.mask_intensity * 100,
      selectedGlitterMask: item.glitter_mask,
      glitterIntensityValue: item.glitter_intensity * 100,
      selectedShimmerMask: item.shimmer_mask,
      shimmerIntensityValue: item.shimmer_intensity * 100,
      r: item.r,
      g: item.g,
      b: item.b,
      a: item.a,
      shimmer_r:item.shimmer_r,
      shimmer_g:item.shimmer_g,
      shimmer_b:item.shimmer_b,
      shimmer_a:item.shimmer_a
    };
    this.setState({ activeEdit });
  };

  editProduct = (item) => {
    var activeEdit = {
      idx: item.idx,
      skuId: item.sku_id,
      skuName: item.sku_name,
      selectedMask: item.product_features[0].mask,
      lipEffectType: item.product_features[0].finish_type,
      foundationEffectType: item.product_features[0].finish_type,
      lipEffectValue: item.product_features[0].finish_intensity * 100,
      foundationEffectValue: item.product_features[0].finish_intensity * 100,
      foundationSmoothingCoefficentValue: item.product_features[0].smoothing_coefficent * 100,
      foundationShininessValue: item.product_features[0].shininess * 100,
      foundationBlendingRatioValue: item.product_features[0].blending_ratio * 100,
      foundationMaskIntensityValue: item.product_features[0].mask_intensity* 100,
      selectedGlitterMask: item.product_features[0].glitter_mask,
      glitterIntensityValue: item.product_features[0].glitter_intensity * 100,
      selectedShimmerMask: item.product_features[0].shimmer_mask,
      shimmerIntensityValue: item.product_features[0].shimmer_intensity * 100,
      r: item.product_features[0].r,
      g: item.product_features[0].g,
      b: item.product_features[0].b,
      a: item.product_features[0].a,
      shimmer_r:item.product_features[0].shimmer_r,
      shimmer_g:item.product_features[0].shimmer_g,
      shimmer_b:item.product_features[0].shimmer_b,
      shimmer_a:item.product_features[0].shimmer_a
    };
    this.setState({ activeEdit });
  };

  cancelUpdate = () => {
    var activeEdit = {};
    
    this.setState({ activeEdit });
  };

  updateProductFeature = () => {
    let skuname = this.state.activeEdit.skuName;
    let skuid = this.state.activeEdit.skuId;
    let mask = undefined;
    let lipeffect_type = undefined;
    let foundationeffect_type = undefined;
    let lipeffect_val = undefined;
    let foundationeffect_val = undefined;
    let glitter_mask = undefined;
    let glitter_intensity = undefined;
    let shimmer_mask = undefined;
    let shimmer_intensity = undefined;
    let foundation_smoothing_coefficent = undefined;
    let foundation_shininess = undefined;
    let foundation_blending_ratio = undefined;
    let foundation_mask_intensity = undefined;

    if (
      this.state.activeEdit.skuName === "" ||
      this.state.activeEdit.skuName === undefined ||
      this.state.activeEdit.skuName === null
    ) {
      skuname = this.props.itemTitle.toUpperCase() + "_" + this.getIndex();
    }
    if (
      this.state.activeEdit.skuId === "" ||
      this.state.activeEdit.skuId === undefined ||
      this.state.activeEdit.skuId === null
    ) {
      skuid = this.props.itemTitle.toUpperCase() + "_" + this.getIndex();
    }
    if (this.state.showMask) {
      if (this.state.activeEdit.selectedMask === undefined) {
        mask = this.getDefaultMask();
      } else {
        mask = this.state.activeEdit.selectedMask;
      }
    }
    if (this.state.showLipEffectType) {
      if (this.state.activeEdit.lipEffectType === undefined) {
        lipeffect_type = 0;
      } else {
        lipeffect_type = this.state.activeEdit.lipEffectType;
      }
    }
    if (this.state.showFoundationEffectType) {
      if (this.state.activeEdit.foundationEffectType === undefined) {
        lipeffect_type = this.getDefaultFoundationFinishType();
      } else {
        lipeffect_type = this.state.activeEdit.foundationEffectType;
      }
    }
    if (this.state.showLipEffectIntensity) {
      if (this.state.activeEdit.lipEffectValue === undefined) {
        lipeffect_val = 1.0;
      } else {
        lipeffect_val = this.state.activeEdit.lipEffectValue / 100;
      }
    }
    if (this.state.showFoundationIntensity) {
      if (this.state.activeEdit.foundationEffectValue === undefined) {
        lipeffect_val = 0.5;
      } else {
        lipeffect_val = this.state.activeEdit.foundationEffectValue / 100;
      }
    }
    if (this.state.showFoundationSmoothingCoefficent) {
      if (
        this.state.activeEdit.foundationSmoothingCoefficentValue === undefined
      ) {
        foundation_smoothing_coefficent = 0.5;
      } else {
        foundation_smoothing_coefficent =
          this.state.activeEdit.foundationSmoothingCoefficentValue / 100;
      }
    }
    if (this.state.showFoundationShininess) {
      if (this.state.activeEdit.foundationShininessValue === undefined) {
        foundation_shininess = 0.5;
      } else {
        foundation_shininess =
          this.state.activeEdit.foundationShininessValue / 100;
      }
    }
    if (this.state.showFoundationBlendingRatio) {
      if (this.state.activeEdit.foundationBlendingRatioValue === undefined) {
        foundation_blending_ratio = 0.5;
      } else {
        foundation_blending_ratio =
          this.state.activeEdit.foundationBlendingRatioValue / 100;
      }
    }
    if (this.state.showFoundationMaskIntensity) {
      if (this.state.activeEdit.foundationMaskIntensityValue === undefined) {
        foundation_mask_intensity = 0.5;
      } else {
        foundation_mask_intensity =
          this.state.activeEdit.foundationMaskIntensityValue / 100;
      }
    }
    if (Object.keys(GlitterMasks).indexOf(this.props.itemTitle) != -1) {
      if (this.state.activeEdit.selectedGlitterMask === undefined) {
        glitter_mask = this.getDefaultGlitterMask();
      } else {
        glitter_mask = this.state.activeEdit.selectedGlitterMask;
      }
      glitter_intensity = this.state.activeEdit.glitterIntensityValue / 100;
    }
    if (Object.keys(ShimmerMasks).indexOf(this.props.itemTitle) != -1) {
      if (this.state.activeEdit.selectedShimmerMask === undefined) {
        shimmer_mask = this.getDefaultShimmerMask();
      } else {
        shimmer_mask = this.state.activeEdit.selectedShimmerMask;
      }
      shimmer_intensity = this.state.activeEdit.shimmerIntensityValue / 100;
    }
    let r = this.state.activeEdit.r;
    let g = this.state.activeEdit.g;
    let b = this.state.activeEdit.b;
    let a = this.state.activeEdit.a;
    
    let shimmer_r = this.state.activeEdit.shimmer_r;
    let shimmer_g = this.state.activeEdit.shimmer_g;
    let shimmer_b = this.state.activeEdit.shimmer_b;
    let shimmer_a = this.state.activeEdit.shimmer_a;
    
    if (r === -1 || g === -1 || b === -1 || a === -1) {
      r = InitialColors[this.props.itemTitle.toLowerCase()][0];
      g = InitialColors[this.props.itemTitle.toLowerCase()][1];
      b = InitialColors[this.props.itemTitle.toLowerCase()][2];
      a = InitialColors[this.props.itemTitle.toLowerCase()][3];
    }
    if (shimmer_r === -1 || shimmer_g === -1 || shimmer_b === -1 || shimmer_a === -1 ) {
      shimmer_r = InitialColors[this.props.itemTitle.toLowerCase()][0];
      shimmer_g = InitialColors[this.props.itemTitle.toLowerCase()][1];
      shimmer_b = InitialColors[this.props.itemTitle.toLowerCase()][2];
      shimmer_a = InitialColors[this.props.itemTitle.toLowerCase()][3];
      
    }
    if (this.state.activeEdit) {
      ProductStore.updateProductFeature(
        this.state.activeEdit.prd_idx,
        this.state.activeEdit.idx,
        skuid,
        skuname,
        this.props.itemTitle.toUpperCase(),
        r,
        g,
        b,
        a,
        lipeffect_val,
        lipeffect_type,
        glitter_mask,
        glitter_intensity,
        shimmer_mask,
        shimmer_intensity,
        mask,
        foundation_smoothing_coefficent,
        foundation_shininess,
        foundation_blending_ratio,
        foundation_mask_intensity,
        shimmer_r,
        shimmer_g,
        shimmer_b,
        shimmer_a
      );
    }
    this.setState({ activeEdit: undefined });
  }

  updateProduct = () => {
    let skuname = this.state.activeEdit.skuName;
    let skuid = this.state.activeEdit.skuId;
    let mask = undefined;
    let lipeffect_type = undefined;
    let foundationeffect_type = undefined;
    let lipeffect_val = undefined;
    let foundationeffect_val = undefined;
    let glitter_mask = undefined;
    let glitter_intensity = undefined;
    let shimmer_mask = undefined;
    let shimmer_intensity = undefined;
    let foundation_smoothing_coefficent = undefined;
    let foundation_shininess = undefined;
    let foundation_blending_ratio = undefined;
    let foundation_mask_intensity = undefined;

    if (
      this.state.activeEdit.skuName === "" ||
      this.state.activeEdit.skuName === undefined ||
      this.state.activeEdit.skuName === null
    ) {
      skuname = this.props.itemTitle.toUpperCase() + "_" + this.getIndex();
    }
    if (
      this.state.activeEdit.skuId === "" ||
      this.state.activeEdit.skuId === undefined ||
      this.state.activeEdit.skuId === null
    ) {
      skuid = this.props.itemTitle.toUpperCase() + "_" + this.getIndex();
    }
    if (this.state.showMask) {
      if (this.state.activeEdit.selectedMask === undefined) {
        mask = this.getDefaultMask();
      } else {
        mask = this.state.activeEdit.selectedMask;
      }
    }
    if (this.state.showLipEffectType) {
      if (this.state.activeEdit.lipEffectType === undefined) {
        lipeffect_type = 0;
      } else {
        lipeffect_type = this.state.activeEdit.lipEffectType;
      }
    }
    if (this.state.showFoundationEffectType) {
      if (this.state.activeEdit.foundationEffectType === undefined) {
        lipeffect_type = this.getDefaultFoundationFinishType();
      } else {
        lipeffect_type = this.state.activeEdit.foundationEffectType;
      }
    }
    if (this.state.showLipEffectIntensity) {
      if (this.state.activeEdit.lipEffectValue === undefined) {
        lipeffect_val = 1.0;
      } else {
        lipeffect_val = this.state.activeEdit.lipEffectValue / 100;
      }
    }
    if (this.state.showFoundationIntensity) {
      if (this.state.activeEdit.foundationEffectValue === undefined) {
        lipeffect_val = 0.5;
      } else {
        lipeffect_val = this.state.activeEdit.foundationEffectValue / 100;
      }
    }
    if (this.state.showFoundationSmoothingCoefficent) {
      if (
        this.state.activeEdit.foundationSmoothingCoefficentValue === undefined
      ) {
        foundation_smoothing_coefficent = 0.5;
      } else {
        foundation_smoothing_coefficent =
          this.state.activeEdit.foundationSmoothingCoefficentValue / 100;
      }
    }
    if (this.state.showFoundationShininess) {
      if (this.state.activeEdit.foundationShininessValue === undefined) {
        foundation_shininess = 0.5;
      } else {
        foundation_shininess =
          this.state.activeEdit.foundationShininessValue / 100;
      }
    }
    if (this.state.showFoundationBlendingRatio) {
      if (this.state.activeEdit.foundationBlendingRatioValue === undefined) {
        foundation_blending_ratio = 0.5;
      } else {
        foundation_blending_ratio =
          this.state.activeEdit.foundationBlendingRatioValue / 100;
      }
    }
    if (this.state.showFoundationMaskIntensity) {
      if (this.state.activeEdit.foundationMaskIntensityValue === undefined) {
        foundation_mask_intensity = 0.5;
      } else {
        foundation_mask_intensity =
          this.state.activeEdit.foundationMaskIntensityValue / 100;
      }
    }
    if (Object.keys(GlitterMasks).indexOf(this.props.itemTitle) != -1) {
      if (this.state.activeEdit.selectedGlitterMask === undefined) {
        glitter_mask = this.getDefaultGlitterMask();
      } else {
        glitter_mask = this.state.activeEdit.selectedGlitterMask;
      }
      glitter_intensity = this.state.activeEdit.glitterIntensityValue / 100;
    }
    if (Object.keys(ShimmerMasks).indexOf(this.props.itemTitle) != -1) {
      if (this.state.activeEdit.selectedShimmerMask === undefined) {
        shimmer_mask = this.getDefaultShimmerMask();
      } else {
        shimmer_mask = this.state.activeEdit.selectedShimmerMask;
      }
      shimmer_intensity = this.state.activeEdit.shimmerIntensityValue / 100;
    }
    let r = this.state.activeEdit.r;
    let g = this.state.activeEdit.g;
    let b = this.state.activeEdit.b;
    let a = this.state.activeEdit.a;

    let shimmer_r = this.state.activeEdit.shimmer_r;
    let shimmer_g = this.state.activeEdit.shimmer_g;
    let shimmer_b = this.state.activeEdit.shimmer_b;   
    let shimmer_a = this.state.activeEdit.shimmer_a;   
    
    if (r === -1 || g === -1 || b === -1 || a === -1) {
      r = InitialColors[this.props.itemTitle.toLowerCase()][0];
      g = InitialColors[this.props.itemTitle.toLowerCase()][1];
      b = InitialColors[this.props.itemTitle.toLowerCase()][2];
      a = InitialColors[this.props.itemTitle.toLowerCase()][3];
    }
    if (shimmer_r === -1 || shimmer_g === -1 || shimmer_b === -1 || shimmer_a === -1 ) {
      shimmer_r = InitialColors[this.props.itemTitle.toLowerCase()][0];
      shimmer_g = InitialColors[this.props.itemTitle.toLowerCase()][1];
      shimmer_b = InitialColors[this.props.itemTitle.toLowerCase()][2];
      shimmer_a = InitialColors[this.props.itemTitle.toLowerCase()][3];
    }
    if (this.state.activeEdit) {
      ProductStore.updateProduct(
        this.state.activeEdit.idx,
        skuid,
        skuname,
        this.props.itemTitle.toUpperCase(),
        r,
        g,
        b,
        a,
        lipeffect_val,
        lipeffect_type,
        glitter_mask,
        glitter_intensity,
        shimmer_mask,
        shimmer_intensity,
        mask,
        foundation_smoothing_coefficent,
        foundation_shininess,
        foundation_blending_ratio,
        foundation_mask_intensity,
        shimmer_r,
        shimmer_g,
        shimmer_b,
        shimmer_a,
      );
    }
    this.setState({ activeEdit: undefined });
  };

  addProduct() {
    let skuname = this.state.skuName;
    let skuid = this.state.skuId;
    let mask = undefined;
    let lipeffect_type = undefined;
    let foundationeffect_type = undefined;
    let lipeffect_val = undefined;
    let foundationeffect_val = undefined;
    let glitter_mask = undefined;
    let glitter_intensity = undefined;
    let shimmer_mask = undefined;
    let shimmer_intensity = undefined;
    let foundation_smoothing_coefficent = undefined;
    let foundation_shininess = undefined;
    let foundation_blending_ratio = undefined;
    let foundation_mask_intensity = undefined;

    if (
      this.state.skuName === "" ||
      this.state.skuName === undefined ||
      this.state.skuName === null
    ) {
      skuname = this.props.itemTitle.toUpperCase() + "_" + this.getIndex();
    }

    if (
      this.state.skuId === "" ||
      this.state.skuId === undefined ||
      this.state.skuId === null
    ) {
      skuid = this.props.itemTitle.toUpperCase() + "_" + this.getIndex();
    }

    if (this.state.showMask) {
      if (this.state.selectedMask === undefined) {
        mask = this.getDefaultMask();
      } else {
        mask = this.state.selectedMask;
      }
    }

    if (this.state.showLipEffectType) {
      if (this.state.lipEffectType === undefined) {
        lipeffect_type = 0;
      } else {
        lipeffect_type = this.state.lipEffectType;
      }
    }
    if (this.state.showFoundationEffectType) {
      if (this.state.foundationEffectType === undefined) {
        lipeffect_type = this.getDefaultFoundationFinishType();
      } else {
        lipeffect_type = this.state.foundationEffectType;
      }
    }

    if (this.state.showLipEffectIntensity) {
      if (this.state.lipEffectValue === undefined) {
        lipeffect_val = 1.0;
      } else {
        lipeffect_val = this.state.lipEffectValue / 100;
      }
    }
    if (this.state.showFoundationIntensity) {
      if (this.state.foundationEffectValue === undefined) {
        lipeffect_val = 0.5;
      } else {
        lipeffect_val = this.state.foundationEffectValue / 100;
      }
    }

    if (this.state.showFoundationSmoothingCoefficent) {
      if (this.state.foundationSmoothingCoefficentValue === undefined) {
        foundation_smoothing_coefficent = 0.5;
      } else {
        foundation_smoothing_coefficent =
          this.state.foundationSmoothingCoefficentValue / 100;
      }
    }
    if (this.state.showFoundationShininess) {
      if (this.state.foundationShininessValue === undefined) {
        foundation_shininess = 0.5;
      } else {
        foundation_shininess = this.state.foundationShininessValue / 100;
      }
    }
    if (this.state.showFoundationBlendingRatio) {
      if (this.state.foundationBlendingRatioValue === undefined) {
        foundation_blending_ratio = 0.5;
      } else {
        foundation_blending_ratio = this.state.foundationBlendingRatioValue / 100;
      }
    }
    if (this.state.showFoundationMaskIntensity) {
      if (this.state.foundationMaskIntensityValue === undefined) {
        foundation_mask_intensity = 0.5;
      } else {
        foundation_mask_intensity = this.state.foundationMaskIntensityValue / 100;
      }
    }
    if (Object.keys(GlitterMasks).indexOf(this.props.itemTitle) != -1) {
      if (this.state.selectedGlitterMask === undefined) {
        glitter_mask = this.getDefaultGlitterMask();
      } else {
        glitter_mask = this.state.selectedGlitterMask;
      }

      glitter_intensity = this.state.glitterIntensityValue / 100;
    }
    if (Object.keys(ShimmerMasks).indexOf(this.props.itemTitle) != -1) {
      if (this.state.selectedShimmerMask === undefined) {
        shimmer_mask = this.getDefaultShimmerMask();
      } else {
        shimmer_mask = this.state.selectedShimmerMask;
      }

      shimmer_intensity = this.state.shimmerIntensityValue / 100;
    }

    let r = this.state.r;
    let g = this.state.g;
    let b = this.state.b;
    let a = this.state.a;

    let shimmer_r = this.state.shimmer_r;
    let shimmer_g = this.state.shimmer_g;
    let shimmer_b = this.state.shimmer_b;
    let shimmer_a = this.state.shimmer_a;

    if (r === -1 || g === -1 || b === -1 || a === -1) {
      r = InitialColors[this.props.itemTitle.toLowerCase()][0];
      g = InitialColors[this.props.itemTitle.toLowerCase()][1];
      b = InitialColors[this.props.itemTitle.toLowerCase()][2];
      a = InitialColors[this.props.itemTitle.toLowerCase()][3];
    }
    if (shimmer_r === -1 || shimmer_g === -1 || shimmer_b === -1 || shimmer_a === -1 ) {
      shimmer_r = InitialColors[this.props.itemTitle.toLowerCase()][0];
      shimmer_g = InitialColors[this.props.itemTitle.toLowerCase()][1];
      shimmer_b = InitialColors[this.props.itemTitle.toLowerCase()][2];
      shimmer_a = InitialColors[this.props.itemTitle.toLowerCase()][3];
    }

    ProductStore.addProduct(
      skuid,
      skuname,
      this.props.itemTitle.toUpperCase(),
      r,
      g,
      b,
      a,
      lipeffect_val,
      lipeffect_type,
      glitter_mask,
      glitter_intensity,
      shimmer_mask,
      shimmer_intensity,
      mask,
      foundation_smoothing_coefficent,
      foundation_shininess,
      foundation_blending_ratio,
      foundation_mask_intensity,
      shimmer_r,
      shimmer_g,
      shimmer_b,
      shimmer_a
    );

    // reset ui
    $(".sku-id-input > input").val("");
    $(".sku-name-input > input").val("");
    if (this.maskDropdownRef && this.maskDropdownRef.current)
      this.maskDropdownRef.current.state.value = 0;
    if (this.leIntensityRef && this.leIntensityRef.current)
      this.leDropdownRef.current.state.value = 0;
    if (this.leIntensityRef && this.leIntensityRef.current)
      this.leIntensityRef.current.state.value = 100;

    if (this.foIntensityRef && this.foIntensityRef.current)
      this.foDropdownRef.current.state.value =
        this.getDefaultFoundationFinishType();
    if (this.foIntensityRef && this.foIntensityRef.current)
      this.foIntensityRef.current.state.value = 50;
    if (
      this.foSmooothingCoefficentRef &&
      this.foSmooothingCoefficentRef.current
    )
      this.foSmooothingCoefficentRef.current.state.value = 50;

    if (
      this.foShininessRef &&
      this.foShininessRef.current
    )
      this.foShininessRef.current.state.value = 50;

    if (
      this.foBlendingRatioRef &&
      this.foBlendingRatioRef.current
    )
      this.foBlendingRatioRef.current.state.value = 50;
    
    if (
      this.foMaskIntensityRef &&
      this.foMaskIntensityRef.current
    )
      this.foMaskIntensityRef.current.state.value = 50;

    if (this.glitterMaskDropdownRef && this.glitterMaskDropdownRef.current)
      this.glitterMaskDropdownRef.current.state.value = 0;
    if (this.glitterIntensityRef && this.glitterIntensityRef.current)
      this.glitterIntensityRef.current.state.value = 0;
    if (this.shimmerMaskDropdownRef && this.shimmerMaskDropdownRef.current)
      this.shimmerMaskDropdownRef.current.state.value = 0;
    if (this.shimmerIntensityRef && this.shimmerIntensityRef.current)
      this.shimmerIntensityRef.current.state.value = 0;

    // reset state
    this.setState({
      selectedMask: undefined,
      skuName: undefined,
      skuId: undefined,
      lipEffectValue: 100,
      lipEffectType: undefined,
      foundationEffectType: undefined,
      foundationEffectValue: 50,
      foundationSmoothingCoefficentValue: 50,
      foundationBlendingRatioValue: 50,
      foundationMaskIntensityValue: 50,
      selectedGlitterMask: undefined,
      glitterIntensityValue: 0,
      selectedShimmerMask: undefined,
      shimmerIntensityValue: 0,
      r: -1,
      g: -1,
      b: -1,
      a: -1,
      isColorPickerActive: false,
      isProductEditColorPickerActive: false,
      isColorFeatureEditPickerActive: false,
      isColorFeatureAddPickerActive: false,
      shimmer_r: -1,
      shimmer_g: -1,
      shimmer_b: -1,
      shimmer_a: -1,
    });
  }

  productsMayHaveMultipleFeatures = () => {
    return ["EYELINER" ,"EYESHADOW" ,"EYELASH" ,"EYEBROW"].includes(this.props.itemTitle.toUpperCase());
  }

  applyMakeup(item, reset_old = true) {
    if (
      !(AppStore.currentPage == AppStore.PagesEnum.MAIN) &&
      AppStore.currentPage == AppStore.PagesEnum.MODEL &&
      AppStore.currentApp == AppStore.AppsEnum.ARTIST
    ) {
      Object.keys(window.SERVICES.vmtCoreCollection).forEach((i) => {
        window.vmt_module._setModelImageBypass(i);
        window.SERVICES.vmtCoreCollection[i].resetDetection();
        window.SERVICES.vmtCoreCollection[i].getDetection();
        if (item.type == "FOUNDATION") {
          window.SERVICES.vmtCoreCollection[i].removeFoundation();
          window.SERVICES.vmtCoreCollection[i].applyFoundation(
            item.r,
            item.g,
            item.b,
            item.a,
            item.mask,
            item.finish_type,
            item.smoothing_coefficent,
            item.finish_intensity,
            item.blending_ratio,
            item.shininess,
            item.mask_intensity
          );
          console.log("==> " + item.type.toUpperCase());
        }
        if (item.type == "FOUNDATION 2") {
          window.SERVICES.vmtCoreCollection[i].removeFoundation_2();
          window.SERVICES.vmtCoreCollection[i].applyFoundation_2(
            item.r,
            item.g,
            item.b,
            item.a,
            item.mask,
            item.finish_type,
            item.finish_intensity,
            item.smoothing_coefficent,
            item.shininess,
            item.blending_ratio,
            item.mask_intensity
          );
          console.log("==> " + item.type.toUpperCase());
        }
        if (item.type == "BLENDER") {
          window.SERVICES.vmtCoreCollection[i].removeBlender();
          window.SERVICES.vmtCoreCollection[i].applyBlender(
            item.r,
            item.g,
            item.b,
            item.a
          );
        }
        if (item.type == "BLUSH") {
          window.SERVICES.vmtCoreCollection[i].removeBlush();
          let shimmer_mask = item["shimmer_mask"] || null;
          let shimmer_intensity = item["shimmer_intensity"] || null;
          window.SERVICES.vmtCoreCollection[i].applyBlush(
            item.mask,
            item.r,
            item.g,
            item.b,
            item.a,
            shimmer_mask,
            shimmer_intensity,
            item.shimmer_r,
            item.shimmer_g,
            item.shimmer_b
          );
        }
        if (item.type == "HIGHLIGHTER") {
          window.SERVICES.vmtCoreCollection[i].removeHighlighter();
          let shimmer_mask = item["shimmer_mask"] || null;
          let shimmer_intensity = item["shimmer_intensity"] || null;
          window.SERVICES.vmtCoreCollection[i].applyHighlighter(
            item.mask,
            item.r,
            item.g,
            item.b,
            item.a,
            shimmer_mask,
            shimmer_intensity
          );
        }
        if (item.type == "LIPLINER") {
          window.SERVICES.vmtCoreCollection[i].removeLipliner();
          window.SERVICES.vmtCoreCollection[i].applyLipliner(
            item.mask,
            item.r,
            item.g,
            item.b,
            item.a
          );
        }
        if (item.type == "CONTOUR") {
          window.SERVICES.vmtCoreCollection[i].removeContour();
          window.SERVICES.vmtCoreCollection[i].applyContour(
            item.mask,
            item.r,
            item.g,
            item.b,
            item.a
          );
        }
        if (item.type == "BRONZER") {
          window.SERVICES.vmtCoreCollection[i].removeBronzer();
          window.SERVICES.vmtCoreCollection[i].applyBronzer(
            item.mask,
            item.r,
            item.g,
            item.b,
            item.a
          );
        }
        if (item.type == "LIPSTICK") {
          window.SERVICES.vmtCoreCollection[i].removeLipstick();
          let glitter_mask = item["glitter_mask"] || null;
          let glitter_intensity = item["glitter_intensity"] || null;
          let shimmer_mask = item["shimmer_mask"] || null;
          let shimmer_intensity = item["shimmer_intensity"] || null;
          window.SERVICES.vmtCoreCollection[i].applyLipstick(
            item.r,
            item.g,
            item.b,
            item.a,
            item.finish_intensity,
            item.finish_type,
            glitter_mask,
            glitter_intensity,
            shimmer_mask,
            shimmer_intensity
          );
        }
        if (item.type == "EYESHADOW") {
          if (reset_old) window.SERVICES.vmtCoreCollection[i].removeEyeshadow();
          let shimmer_mask = item["shimmer_mask"] || null;
          let shimmer_intensity = item["shimmer_intensity"] || null;
          window.SERVICES.vmtCoreCollection[i].applyEyeshadow(
            item.mask,
            item.r,
            item.g,
            item.b,
            item.a,
            shimmer_mask,
            shimmer_intensity,
            item.shimmer_r,
            item.shimmer_g,
            item.shimmer_b,
            item.shimmer_a
          );
        }
        if (item.type == "EYELASH") {
          if (reset_old) window.SERVICES.vmtCoreCollection[i].removeEyelash();
          window.SERVICES.vmtCoreCollection[i].applyEyelash(
            item.mask,
            item.r,
            item.g,
            item.b,
            item.a
          );
        }
        if (item.type == "EYELINER") {
          if (reset_old) window.SERVICES.vmtCoreCollection[i].removeEyeliner();
          window.SERVICES.vmtCoreCollection[i].applyEyeliner(
            item.mask,
            item.r,
            item.g,
            item.b,
            item.a
          );
        }
        if (item.type == "EYEBROW") {
          if (reset_old) window.SERVICES.vmtCoreCollection[i].removeEyebrow();
          window.SERVICES.vmtCoreCollection[i].applyEyebrow(
            item.mask,
            item.r,
            item.g,
            item.b,
            item.a
          );
        }
        window.SERVICES.vmtCoreCollection[i].render();
      });
    } else {
      if (item.type == "FOUNDATION") {
        window.SERVICES.vmtcore.removeFoundation();
        window.SERVICES.vmtcore.applyFoundation(
          item.r,
          item.g,
          item.b,
          item.a,
          item.mask,
          item.finish_type,
          item.smoothing_coefficent,
          item.finish_intensity,
          item.blending_ratio,
          item.shininess,
          item.mask_intensity
        );
        console.log("==> " + item.type.toUpperCase());
      }
      if (item.type == "FOUNDATION 2") {
        window.SERVICES.vmtcore.removeFoundation_2();
        window.SERVICES.vmtcore.applyFoundation_2(
          item.r,
          item.g,
          item.b,
          item.a,
          item.mask,
          item.finish_type,
          item.finish_intensity,
          item.smoothing_coefficent,
          item.shininess,
          item.blending_ratio,
          item.mask_intensity
        );
        console.log("==> " + item.type.toUpperCase());
      }
      if (item.type == "BLENDER") {
        window.SERVICES.vmtcore.removeBlender();
        window.SERVICES.vmtcore.applyBlender(item.r, item.g, item.b, item.a);
      }
      if (item.type == "BLUSH") {
        window.SERVICES.vmtcore.removeBlush();
        let shimmer_mask = item["shimmer_mask"] || null;
        let shimmer_intensity = item["shimmer_intensity"] || null;
        window.SERVICES.vmtcore.applyBlush(
          item.mask,
          item.r,
          item.g,
          item.b,
          item.a,
          shimmer_mask,
          shimmer_intensity,
          item.shimmer_g,
          item.shimmer_b,
          item.shimmer_a,
        );
      }
      if (item.type == "HIGHLIGHTER") {
    window.SERVICES.vmtcore.removeHighlighter();
        let shimmer_mask = item["shimmer_mask"] || null;
        let shimmer_intensity = item["shimmer_intensity"] || null;
        window.SERVICES.vmtcore.applyHighlighter(
          item.mask,
          item.r,
          item.g,
          item.b,
          item.a,
          shimmer_mask,
          shimmer_intensity
        );
      }
      if (item.type == "LIPLINER") {
        window.SERVICES.vmtcore.removeLipliner();
        window.SERVICES.vmtcore.applyLipliner(
          item.mask,
          item.r,
          item.g,
          item.b,
          item.a
        );
      }
      if (item.type == "CONTOUR") {
        window.SERVICES.vmtcore.removeContour();
        window.SERVICES.vmtcore.applyContour(
          item.mask,
          item.r,
          item.g,
          item.b,
          item.a
        );
      }
      if (item.type == "BRONZER") {
        window.SERVICES.vmtcore.removeBronzer();
        window.SERVICES.vmtcore.applyBronzer(
          item.mask,
          item.r,
          item.g,
          item.b,
          item.a
        );
      }
      if (item.type == "LIPSTICK") {
        window.SERVICES.vmtcore.removeLipstick();
        let glitter_mask = item["glitter_mask"] || null;
        let glitter_intensity = item["glitter_intensity"] || null;
        let shimmer_mask = item["shimmer_mask"] || null;
        let shimmer_intensity = item["shimmer_intensity"] || null;
        window.SERVICES.vmtcore.applyLipstick(
          item.r,
          item.g,
          item.b,
          item.a,
          item.finish_intensity,
          item.finish_type,
          glitter_mask,
          glitter_intensity,
          shimmer_mask,
          shimmer_intensity
        );
      }
      if (item.type == "EYESHADOW") {
        if (reset_old) window.SERVICES.vmtcore.removeEyeshadow();

        let shimmer_mask = item["shimmer_mask"] || null;
        let shimmer_intensity = item["shimmer_intensity"] || null;

        window.SERVICES.vmtcore.applyEyeshadow(
          item.mask,
          item.r,
          item.g,
          item.b,
          item.a,
          shimmer_mask,
          shimmer_intensity,
          item.shimmer_r,
          item.shimmer_g,
          item.shimmer_b,
          item.shimmer_a,
        );
      }
      if (item.type == "EYELASH") {
        if (reset_old) window.SERVICES.vmtcore.removeEyelash();
        window.SERVICES.vmtcore.applyEyelash(
          item.mask,
          item.r,
          item.g,
          item.b,
          item.a
        );
      }
      if (item.type == "EYELINER") {
        if (reset_old) window.SERVICES.vmtcore.removeEyeliner();
        window.SERVICES.vmtcore.applyEyeliner(
          item.mask,
          item.r,
          item.g,
          item.b,
          item.a
        );
      }
      if (item.type == "EYEBROW") {
        if (reset_old) window.SERVICES.vmtcore.removeEyebrow();
        window.SERVICES.vmtcore.applyEyebrow(
          item.mask,
          item.r,
          item.g,
          item.b,
          item.a
        );
      }
    }
  }

  applyMakeupFromState() {
    var self = this;
    setTimeout(() => {
      var item = self.state;
      if (item.selectedMask == undefined)
        item.selectedMask = this.getDefaultMask();
      if (item.lipEffectValue == undefined) item.lipEffectValue = 100;
      if (item.lipEffectType == undefined) item.lipEffectType = 0;
      if (item.foundationEffectType == undefined)
        item.foundationEffectType = this.getDefaultFoundationFinishType();
      if (item.foundationEffectValue == undefined)
        item.foundationEffectValue = 50;
      if (item.foundationSmoothingCoefficentValue == undefined)
        item.foundationSmoothingCoefficentValue = 50;

      if (item.foundationShininessValue == undefined)
        item.foundationShininessValue = 50;
      
      if (item.foundationBlendingRatioValue == undefined)
        item.foundationBlendingRatioValue = 50;
      
        if (item.foundationMaskIntensityValue == undefined)
        item.foundationMaskIntensityValue = 50;

      if (item.r === -1 || item.g === -1 || item.b === -1 || item.a === -1) {
        item.r = InitialColors[this.props.itemTitle.toLowerCase()][0];
        item.g = InitialColors[this.props.itemTitle.toLowerCase()][1];
        item.b = InitialColors[this.props.itemTitle.toLowerCase()][2];
        item.a = InitialColors[this.props.itemTitle.toLowerCase()][3];
      }
      if (item.shimmer_r === -1 || item.shimmer_g === -1 || item.shimmer_b === -1 || item.shimmer_a === -1) {
        item.shimmer_r = InitialColors[this.props.itemTitle.toLowerCase()][0];
        item.shimmer_g = InitialColors[this.props.itemTitle.toLowerCase()][1];
        item.shimmer_b = InitialColors[this.props.itemTitle.toLowerCase()][2];
        item.shimmer_a = InitialColors[this.props.itemTitle.toLowerCase()][3];
      }

      if (
        !(AppStore.currentPage == AppStore.PagesEnum.MAIN) &&
        AppStore.currentPage == AppStore.PagesEnum.MODEL &&
        AppStore.currentApp == AppStore.AppsEnum.ARTIST
      ) {
        Object.keys(window.SERVICES.vmtCoreCollection).forEach((i) => {
          window.vmt_module._setModelImageBypass(i);
          window.SERVICES.vmtCoreCollection[i].resetDetection();
          window.SERVICES.vmtCoreCollection[i].getDetection();

          if (self.props.itemTitle.toUpperCase() == "FOUNDATION") {
            if (
              item.foundationEffectType == undefined ||
              item.foundationEffectValue == undefined
            )
              return;
            window.SERVICES.vmtCoreCollection[i].removeFoundation();
            window.SERVICES.vmtCoreCollection[i].removeFoundation_2();
            window.SERVICES.vmtCoreCollection[i].applyFoundation(
              item.r,
              item.g,
              item.b,
              item.a,
              item.selectedMask,
              item.foundationEffectType,
              item.foundationSmoothingCoefficentValue / 100,
              item.foundationEffectValue / 100,
              item.foundationBlendingRatioValue / 100,
              item.foundationShininessValue / 100,
              item.foundationMaskIntensityValue / 100
            );
            console.log("==> " + self.props.itemTitle.toUpperCase());
          }
          if (self.props.itemTitle.toUpperCase() == "FOUNDATION 2") {
            if (
              item.foundationEffectType == undefined ||
              item.foundationEffectValue == undefined
            )
              return;
            window.SERVICES.vmtCoreCollection[i].removeFoundation();
            window.SERVICES.vmtCoreCollection[i].removeFoundation_2();

            window.SERVICES.vmtCoreCollection[i].applyFoundation_2(
              item.r,
              item.g,
              item.b,
              item.a,
              item.selectedMask,
              item.foundationEffectType,
              item.foundationEffectValue / 100,
              item.foundationSmoothingCoefficentValue / 100,
              item.foundationShininessValue / 100,
              item.foundationBlendingRatioValue / 100,
              item.foundationMaskIntensityValue / 100
            );
            console.log("==> " + self.props.itemTitle.toUpperCase());
          }

          if (self.props.itemTitle.toUpperCase() == "BLENDER") {
            window.SERVICES.vmtCoreCollection[i].removeBlender();
            window.SERVICES.vmtCoreCollection[i].applyBlender(
              item.r,
              item.g,
              item.b,
              item.a
            );
          }
          if (self.props.itemTitle.toUpperCase() == "BLUSH") {
            window.SERVICES.vmtCoreCollection[i].removeBlush();
            if (item.selectedMask == undefined) return;
            let shimmer_mask =
              item.selectedShimmerMask || self.getDefaultShimmerMask() || null;
            let shimmer_intensity = item.shimmerIntensityValue / 100 || null;
            window.SERVICES.vmtCoreCollection[i].applyBlush(
              item.selectedMask,
              item.r,
              item.g,
              item.b,
              item.a,
              shimmer_mask,
              shimmer_intensity,
              item.shimmer_r,
              item.shimmer_g,
              item.shimmer_b
            );
          }
          if (self.props.itemTitle.toUpperCase() == "HIGHLIGHTER") {
            window.SERVICES.vmtCoreCollection[i].removeHighlighter();
            if (item.selectedMask == undefined) return;
            let shimmer_mask =
              item.selectedShimmerMask || self.getDefaultShimmerMask() || null;
            let shimmer_intensity = item.shimmerIntensityValue / 100 || null;
            window.SERVICES.vmtCoreCollection[i].applyHighlighter(
              item.selectedMask,
              item.r,
              item.g,
              item.b,
              item.a,
              shimmer_mask,
              shimmer_intensity
            );
          }
          if (self.props.itemTitle.toUpperCase() == "LIPLINER") {
            window.SERVICES.vmtCoreCollection[i].removeLipliner();
            if (item.selectedMask == undefined) return;
            window.SERVICES.vmtCoreCollection[i].applyLipliner(
              item.selectedMask,
              item.r,
              item.g,
              item.b,
              item.a
            );
          }
          if (self.props.itemTitle.toUpperCase() == "CONTOUR") {
            window.SERVICES.vmtCoreCollection[i].removeContour();
            if (item.selectedMask == undefined) return;
            window.SERVICES.vmtCoreCollection[i].applyContour(
              item.selectedMask,
              item.r,
              item.g,
              item.b,
              item.a
            );
          }
          if (self.props.itemTitle.toUpperCase() == "BRONZER") {
            window.SERVICES.vmtCoreCollection[i].removeBronzer();
            if (item.selectedMask == undefined) return;
            window.SERVICES.vmtCoreCollection[i].applyBronzer(
              item.selectedMask,
              item.r,
              item.g,
              item.b,
              item.a
            );
          }
          if (self.props.itemTitle.toUpperCase() == "LIPSTICK") {
            window.SERVICES.vmtCoreCollection[i].removeLipstick();
            if (
              item.lipEffectType == undefined ||
              item.lipEffectValue == undefined
            )
              return;
            let glitter_mask =
              item.selectedGlitterMask || self.getDefaultGlitterMask() || null;
            let glitter_intensity = item.glitterIntensityValue / 100 || null;
            let shimmer_mask =
              item.selectedShimmerMask || self.getDefaultShimmerMask() || null;
            let shimmer_intensity = item.shimmerIntensityValue / 100 || null;
            window.SERVICES.vmtCoreCollection[i].applyLipstick(
              item.r,
              item.g,
              item.b,
              item.a,
              item.lipEffectValue / 100,
              item.lipEffectType,
              glitter_mask,
              glitter_intensity,
              shimmer_mask,
              shimmer_intensity
            );
          }
          if (self.props.itemTitle.toUpperCase() == "EYESHADOW") {
            window.SERVICES.vmtCoreCollection[i].removeEyeshadow();
            if (item.selectedMask == undefined) return;
            let shimmer_mask =
              item.selectedShimmerMask || self.getDefaultShimmerMask() || null;
            let shimmer_intensity = item.shimmerIntensityValue / 100 || null;
            window.SERVICES.vmtCoreCollection[i].applyEyeshadow(
              item.selectedMask,
              item.r,
              item.g,
              item.b,
              item.a,
              shimmer_mask,
              shimmer_intensity,
              item.shimmer_r,
              item.shimmer_g,
              item.shimmer_b,
              item.shimmer_a
            );
          }
          if (self.props.itemTitle.toUpperCase() == "EYELASH") {
            window.SERVICES.vmtCoreCollection[i].removeEyelash();
            if (item.selectedMask == undefined) return;
            window.SERVICES.vmtCoreCollection[i].applyEyelash(
              item.selectedMask,
              item.r,
              item.g,
              item.b,
              item.a
            );
          }
          if (self.props.itemTitle.toUpperCase() == "EYELINER") {
            window.SERVICES.vmtCoreCollection[i].removeEyeliner();
            if (item.selectedMask == undefined) return;
            window.SERVICES.vmtCoreCollection[i].applyEyeliner(
              item.selectedMask,
              item.r,
              item.g,
              item.b,
              item.a
            );
          }
          if (self.props.itemTitle.toUpperCase() == "EYEBROW") {
            window.SERVICES.vmtCoreCollection[i].removeEyebrow();
            if (item.selectedMask == undefined) return;
            window.SERVICES.vmtCoreCollection[i].applyEyebrow(
              item.selectedMask,
              item.r,
              item.g,
              item.b,
              item.a
            );
          }

          window.SERVICES.vmtCoreCollection[i].render();
        });
      } else {
        if (self.props.itemTitle.toUpperCase() == "FOUNDATION") {
          if (
            item.foundationEffectType == undefined ||
            item.foundationEffectValue == undefined ||
            item.foundationSmoothingCoefficentValue == undefined ||
            item.foundationShininessValue == undefined ||
            item.foundationBlendingRatioValue == undefined ||
            item.foundationMaskIntensityValue == undefined
          )
            return;
          window.SERVICES.vmtcore.removeFoundation();
          window.SERVICES.vmtcore.removeFoundation_2();
          window.SERVICES.vmtcore.applyFoundation(
            item.r,
            item.g,
            item.b,
            item.a,
            item.selectedMask,
            item.foundationEffectType,
            item.foundationSmoothingCoefficentValue / 100,
            item.foundationEffectValue / 100,
            item.foundationBlendingRatioValue / 100,
            item.foundationShininessValue / 100,
            item.foundationMaskIntensityValue / 100
          );
          console.log("==> " + self.props.itemTitle.toUpperCase());
        }

        if (self.props.itemTitle.toUpperCase() == "FOUNDATION 2") {
          if (
            item.foundationEffectType == undefined ||
            item.foundationEffectValue == undefined ||
            item.foundationSmoothingCoefficentValue == undefined||
            item.foundationShininessValue == undefined||
            item.foundationBlendingRatioValue == undefined ||
            item.foundationMaskIntensityValue == undefined
          )
            return;
          window.SERVICES.vmtcore.removeFoundation();
          window.SERVICES.vmtcore.removeFoundation_2();
          window.SERVICES.vmtcore.applyFoundation_2(
            item.r,
            item.g,
            item.b,
            item.a,
            item.selectedMask,
            item.foundationEffectType,
            item.foundationEffectValue / 100,
            item.foundationSmoothingCoefficentValue / 100,
            item.foundationShininessValue / 100,
            item.foundationBlendingRatioValue / 100,
            item.foundationMaskIntensityValue / 100
          );
          console.log("==> " + self.props.itemTitle.toUpperCase());
        }
        if (self.props.itemTitle.toUpperCase() == "BLENDER") {
          window.SERVICES.vmtcore.removeBlender();
          window.SERVICES.vmtcore.applyBlender(item.r, item.g, item.b, item.a);
        }
        if (self.props.itemTitle.toUpperCase() == "BLUSH") {
          window.SERVICES.vmtcore.removeBlush();
          if (item.selectedMask == undefined) return;
          let shimmer_mask =
            item.selectedShimmerMask || self.getDefaultShimmerMask() || null;
          let shimmer_intensity = item.shimmerIntensityValue / 100 || null;
          window.SERVICES.vmtcore.applyBlush(
            item.selectedMask,
            item.r,
            item.g,
            item.b,
            item.a,
            shimmer_mask,
            shimmer_intensity,
            item.shimmer_r,
            item.shimmer_g,
            item.shimmer_b
          );
        }
        if (self.props.itemTitle.toUpperCase() == "HIGHLIGHTER") {
          window.SERVICES.vmtcore.removeHighlighter();
          if (item.selectedMask == undefined) return;
          let shimmer_mask =
            item.selectedShimmerMask || self.getDefaultShimmerMask() || null;
          let shimmer_intensity = item.shimmerIntensityValue / 100 || null;
          window.SERVICES.vmtcore.applyHighlighter(
            item.selectedMask,
            item.r,
            item.g,
            item.b,
            item.a,
            shimmer_mask,
            shimmer_intensity
          );
        }
        if (self.props.itemTitle.toUpperCase() == "LIPLINER") {
          window.SERVICES.vmtcore.removeLipliner();
          if (item.selectedMask == undefined) return;
          window.SERVICES.vmtcore.applyLipliner(
            item.selectedMask,
            item.r,
            item.g,
            item.b,
            item.a
          );
        }
        if (self.props.itemTitle.toUpperCase() == "CONTOUR") {
          window.SERVICES.vmtcore.removeContour();
          if (item.selectedMask == undefined) return;
          window.SERVICES.vmtcore.applyContour(
            item.selectedMask,
            item.r,
            item.g,
            item.b,
            item.a
          );
        }
        if (self.props.itemTitle.toUpperCase() == "BRONZER") {
          window.SERVICES.vmtcore.removeBronzer();
          if (item.selectedMask == undefined) return;
          window.SERVICES.vmtcore.applyBronzer(
            item.selectedMask,
            item.r,
            item.g,
            item.b,
            item.a
          );
        }
        if (self.props.itemTitle.toUpperCase() == "LIPSTICK") {
          window.SERVICES.vmtcore.removeLipstick();
          if (
            item.lipEffectType == undefined ||
            item.lipEffectValue == undefined
          )
            return;
          let glitter_mask =
            item.selectedGlitterMask || self.getDefaultGlitterMask() || null;
          let glitter_intensity = item.glitterIntensityValue / 100 || null;
          let shimmer_mask =
            item.selectedShimmerMask || self.getDefaultShimmerMask() || null;
          let shimmer_intensity = item.shimmerIntensityValue / 100 || null;
          window.SERVICES.vmtcore.applyLipstick(
            item.r,
            item.g,
            item.b,
            item.a,
            item.lipEffectValue / 100,
            item.lipEffectType,
            glitter_mask,
            glitter_intensity,
            shimmer_mask,
            shimmer_intensity
          );
        }
        if (self.props.itemTitle.toUpperCase() == "EYESHADOW") {
          window.SERVICES.vmtcore.removeEyeshadow();
          if (item.selectedMask == undefined) return;
          let shimmer_mask =
            item.selectedShimmerMask || self.getDefaultShimmerMask() || null;
          let shimmer_intensity = item.shimmerIntensityValue / 100 || null;
          window.SERVICES.vmtcore.applyEyeshadow(
            item.r,
            item.g,
            item.b,
            item.a,
            item.selectedMask,
            shimmer_mask,
            shimmer_intensity,
            item.shimmer_r,
            item.shimmer_g,
            item.shimmer_b,
            item.shimmer_a
          );
        }
        if (self.props.itemTitle.toUpperCase() == "EYELASH") {
          window.SERVICES.vmtcore.removeEyelash();
          if (item.selectedMask == undefined) return;
          window.SERVICES.vmtcore.applyEyelash(
            item.selectedMask,
            item.r,
            item.g,
            item.b,
            item.a
          );
        }
        if (self.props.itemTitle.toUpperCase() == "EYELINER") {
          window.SERVICES.vmtcore.removeEyeliner();
          if (item.selectedMask == undefined) return;
          window.SERVICES.vmtcore.applyEyeliner(
            item.selectedMask,
            item.r,
            item.g,
            item.b,
            item.a
          );
        }
        if (self.props.itemTitle.toUpperCase() == "EYEBROW") {
          window.SERVICES.vmtcore.removeEyebrow();
          if (item.selectedMask == undefined) return;
          window.SERVICES.vmtcore.applyEyebrow(
            item.selectedMask,
            item.r,
            item.g,
            item.b,
            item.a
          );
        }
      }
    }, 250);
  }

  clickOutsideClrPickerScope(evt, self) {
    // console.log(evt, evt.target)
    if (evt && evt.path) {
      for (let i = 0; i < evt.path.length; i++) {
        let elm = evt.path[i];
        if (elm.id === "color-picker") {
          return;
        }
      }
      self.setState({
        isColorPickerActive: false,
        isColorFeatureEditPickerActive: false,
        isColorFeatureAddPickerActive: false,
        isProductEditColorPickerActive: false
      });
    }
  }

  showColorPicker() {
    var self = this;

    function clrPickerClickHandler(evt) {
      self.clickOutsideClrPickerScope(evt, self);
    }

    document.addEventListener("click", clrPickerClickHandler, false);
    self.setState({ isColorPickerActive: !self.state.isColorPickerActive });

    self.interval = setInterval(() => {
      if (!self.state.isColorPickerActive) {
        document.removeEventListener("click", clrPickerClickHandler, false);
        clearInterval(self.interval);
      }
    }, 500);
  }

  showProductEditColorPicker() {
    var self = this;

    function clrPickerClickHandler(evt) {
      self.clickOutsideClrPickerScope(evt, self);
    }

    document.addEventListener("click", clrPickerClickHandler, false);
    self.setState({ isProductEditColorPickerActive: !self.state.isProductEditColorPickerActive });

    self.interval = setInterval(() => {
      if (!self.state.isProductEditColorPickerActive) {
        document.removeEventListener("click", clrPickerClickHandler, false);
        clearInterval(self.interval);
      }
    }, 500);
  }

  showFeatureEditColorPicker() {
    var self = this;

    function clrPickerClickHandler(evt) {
      self.clickOutsideClrPickerScope(evt, self);
    }

    document.addEventListener("click", clrPickerClickHandler, false);
    self.setState({ isColorFeatureEditPickerActive: !self.state.isColorFeatureEditPickerActive });

    self.interval = setInterval(() => {
      if (!self.state.isColorFeatureEditPickerActive) {
        document.removeEventListener("click", clrPickerClickHandler, false);
        clearInterval(self.interval);
      }
    }, 500);
  }

  showFeatureAddColorPicker() {
    var self = this;

    function clrPickerClickHandler(evt) {
      self.clickOutsideClrPickerScope(evt, self);
    }

    document.addEventListener("click", clrPickerClickHandler, false);
    self.setState({ isColorFeatureAddPickerActive: !self.state.isColorFeatureAddPickerActive });

    self.interval = setInterval(() => {
      if (!self.state.isColorFeatureAddPickerActive) {
        document.removeEventListener("click", clrPickerClickHandler, false);
        clearInterval(self.interval);
      }
    }, 500);
  }

  componentWillMount() {
    if (
      [
        "Foundation",
        "Foundation 2",
        "Blush",
        "Highlighter",
        "Bronzer",
        "Contour",
        "Lipliner",
        "Eyelash",
        "Eyeshadow",
        "Eyeliner",
        "Eyebrow",
      ].indexOf(this.props.itemTitle) != -1
    ) {
      this.setState({ showMask: true });
    }

    if (this.props.itemTitle == "Blush") {
      this.setState({
        showBlushShimmerColor: true
      });
    }
    if (this.props.itemTitle == "Eyeshadow") {
      this.setState({
        showEyeshadowShimmerColor: true
      });
    }

    if (this.props.itemTitle == "Lipstick") {
      this.setState({
        showLipEffectType: true,
        showLipEffectIntensity: true,
      });
    }
    if (
      this.props.itemTitle == "Foundation" ||
      this.props.itemTitle == "Foundation 2"
    ) {
      this.setState({
        showFoundationEffectType: true,
        showFoundationIntensity: true,
        showFoundationSmoothingCoefficent: true,
        showFoundationShininess: true,
        showFoundationBlendingRatio: true,
        showFoundationMaskIntensity: true
      });
    }
  }

  returnComponentColor() {
    var clr = InitialColors[this.props.itemTitle.toLowerCase()];
    return window.RGBAToHexA(clr[0], clr[1], clr[2], clr[3]);
  }

  getDefaultMask() {
    let lowerCasedTitle = this.props.itemTitle.toLowerCase();
    let capitalized =
      lowerCasedTitle.charAt(0).toUpperCase() + lowerCasedTitle.slice(1);
    if (Object.keys(Masks).indexOf(capitalized) != -1) {
      return Masks[capitalized][0]["name"];
    } else {
      return undefined;
    }
  }

  getDefaultGlitterMask() {
    let lowerCasedTitle = this.props.itemTitle.toLowerCase();
    let capitalized =
      lowerCasedTitle.charAt(0).toUpperCase() + lowerCasedTitle.slice(1);
    if (GlitterMasks.hasOwnProperty(capitalized)) {
      return GlitterMasks[capitalized][0]["name"];
    } else {
      return undefined;
    }
  }

  getDefaultShimmerMask() {
    let lowerCasedTitle = this.props.itemTitle.toLowerCase();
    let capitalized =
      lowerCasedTitle.charAt(0).toUpperCase() + lowerCasedTitle.slice(1);
    if (ShimmerMasks.hasOwnProperty(capitalized)) {
      return ShimmerMasks[capitalized][0]["name"];
    } else {
      return undefined;
    }
  }

  getBackgroundColor() {
    let r = this.state.r;
    let g = this.state.g;
    let b = this.state.b;
    let a = this.state.a;


    if (this.state?.activeEdit?.r) {
      r = this.state.activeEdit.r;
      g = this.state.activeEdit.g;
      b = this.state.activeEdit.b;
      a = this.state.activeEdit.a;
    }

    if (r === -1 || g === -1 || b === -1 || a === -1) {
      r = InitialColors[this.props.itemTitle.toLowerCase()][0];
      g = InitialColors[this.props.itemTitle.toLowerCase()][1];
      b = InitialColors[this.props.itemTitle.toLowerCase()][2];
      a = InitialColors[this.props.itemTitle.toLowerCase()][3];
    }
    return window.RGBAToHexA(r, g, b, a);
  }
  getBackgroundColorForBlushShimmer() {
    let r = this.state.shimmer_r;
    let g = this.state.shimmer_g;
    let b = this.state.shimmer_b;
    let a = 1;


    if (this.state?.activeEdit?.shimmer_r) {
      r = this.state.activeEdit.shimmer_r;
      g = this.state.activeEdit.shimmer_g;
      b = this.state.activeEdit.shimmer_b;
      //a = this.state.activeEdit.a;
    }

    if (r === -1 || g === -1 || b === -1 || a === -1) {
      r = InitialColors[this.props.itemTitle.toLowerCase()][0];
      g = InitialColors[this.props.itemTitle.toLowerCase()][1];
      b = InitialColors[this.props.itemTitle.toLowerCase()][2];
      a = InitialColors[this.props.itemTitle.toLowerCase()][3];
    }
    return window.RGBAToHexA(r, g, b, a);
  }
  
  getBackgroundColorForEyeshadowShimmer() {
    let r = this.state.shimmer_r;
    let g = this.state.shimmer_g;
    let b = this.state.shimmer_b;
    let a = 1;


    if (this.state?.activeEdit?.shimmer_r) {
      r = this.state.activeEdit.shimmer_r;
      g = this.state.activeEdit.shimmer_g;
      b = this.state.activeEdit.shimmer_b;
      a = this.state.activeEdit.shimmer_a;
      //a = this.state.activeEdit.a;
    }

    if (r === -1 || g === -1 || b === -1 || a === -1) {
      r = InitialColors[this.props.itemTitle.toLowerCase()][0];
      g = InitialColors[this.props.itemTitle.toLowerCase()][1];
      b = InitialColors[this.props.itemTitle.toLowerCase()][2];
      a = InitialColors[this.props.itemTitle.toLowerCase()][3];
    }
    return window.RGBAToHexA(r, g, b, a);
  }
  getDefaultFoundationFinishType() {
    return "Matte";
  }
  render() {
    return (
      <Table size="small" textAlign="center" verticalAlign="middle" compact>
      {
        !this.productsMayHaveMultipleFeatures() &&
        <Table.Header>
        <Table.Row>
          <Table.HeaderCell>ID</Table.HeaderCell>
          <Table.HeaderCell>SKU ID</Table.HeaderCell>
          <Table.HeaderCell>SKU Name</Table.HeaderCell>
          {this.state.showMask && <Table.HeaderCell>Mask</Table.HeaderCell>}
          <Table.HeaderCell>Color</Table.HeaderCell>
          {this.state.showLipEffectType && (
            <Table.HeaderCell>Finish</Table.HeaderCell>
          )}
          {this.state.showFoundationEffectType && (
            <Table.HeaderCell>Finish</Table.HeaderCell>
          )}
          {this.state.showLipEffectIntensity && (
            <Table.HeaderCell>Finish Intensity</Table.HeaderCell>
          )}
          {this.state.showFoundationIntensity && (
            <Table.HeaderCell>Finish Intensity</Table.HeaderCell>
          )}
          {this.state.showFoundationSmoothingCoefficent && (
            <Table.HeaderCell>Smoothing Coefficent</Table.HeaderCell>
          )}
          {this.state.showFoundationShininess && (
            <Table.HeaderCell>Shininess</Table.HeaderCell>
          )}
          {this.state.showFoundationBlendingRatio && (
            <Table.HeaderCell>Blending Ratio</Table.HeaderCell>
          )}
          {this.state.showFoundationMaskIntensity && (
            <Table.HeaderCell>Mask Intensity</Table.HeaderCell>
          )}
          {Object.keys(GlitterMasks).indexOf(this.props.itemTitle) != -1 && (
            <Table.HeaderCell>Glitter Mask</Table.HeaderCell>
          )}
          {Object.keys(GlitterMasks).indexOf(this.props.itemTitle) != -1 && (
            <Table.HeaderCell>Glitter Intensity</Table.HeaderCell>
          )}
          {Object.keys(ShimmerMasks).indexOf(this.props.itemTitle) != -1 && (
            <Table.HeaderCell>Shimmer Mask</Table.HeaderCell>
          )}
          {Object.keys(ShimmerMasks).indexOf(this.props.itemTitle) != -1 && (
            <Table.HeaderCell>Shimmer Intensity</Table.HeaderCell>
          )}
          {this.state.showBlushShimmerColor && (
            <Table.HeaderCell>Shimmer Color</Table.HeaderCell>
          )}
          {this.state.showEyeshadowShimmerColor && (
            <Table.HeaderCell>Shimmer Color</Table.HeaderCell>
          )}
          <Table.HeaderCell>Modify</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      }
        <CustomTableBody>
          {ProductStore.getProductsByType(
            this.props.itemTitle.toLowerCase()
          ).map((item, index) => {
            return this.state.activeEdit?.idx === item.idx && this.state.activeEdit.prd_idx == undefined ? (
              <Table.Row>
                <Table.Cell collapsing>{this.getIndex()}</Table.Cell>
                <Table.Cell>
                  <Input
                    className="sku-id-input"
                    value={this.state.activeEdit.skuId}
                    onChange={(e) => {
                      this.setState({
                        activeEdit: {
                          ...this.state.activeEdit,
                          skuId: e.target.value,
                        },
                      });
                    }}
                    fluid
                  />
                </Table.Cell>
                <Table.Cell>
                  <Input
                    className="sku-name-input"
                    value={this.state.activeEdit.skuName}
                    onChange={(e) => {
                      this.setState({
                        activeEdit: {
                          ...this.state.activeEdit,
                          skuName: e.target.value,
                        },
                      });
                    }}
                    fluid
                    placeholder={`${this.props.itemTitle.toUpperCase()}_${this.getIndex()}`}
                  />
                </Table.Cell>
                {this.state.showMask && (
                  <Table.Cell>
                    <Dropdown
                      className="mask-selection-dropdown"
                      value={this.state.activeEdit.selectedMask}
                      ref={this.maskDropdownRef}
                      onChange={(e, d) => {
                        this.setState({
                          activeEdit: {
                            ...this.state.activeEdit,
                            selectedMask: d.value,
                          },
                        });
                        this.applyMakeupFromState();
                      }}
                      fluid
                      scrolling
                      selection
                      defaultValue={this.state.activeEdit.selectedMask}
                      options={Masks[this.props.itemTitle]}
                    />
                  </Table.Cell>
                )}
                <Table.Cell id="color-picker" collapsing>
                  <CustomButton size="mini" onClick={this.showProductEditColorPicker}>
                    <SelectColorText>Select Color</SelectColorText>
                    <ColorIndicator
                      style={{
                        backgroundColor: `${this.getBackgroundColor()}`,
                      }}
                    ></ColorIndicator>
                  </CustomButton>
                  {this.state.isProductEditColorPickerActive && (
                    <PopOver>
                      {false && (
                        <Cover
                          onClick={() =>
                            this.setState({
                              activeEdit: {
                                ...this.state.activeEdit,
                              },
                              isProductEditColorPickerActive: false,
                            })
                          }
                        ></Cover>
                      )}
                      <SketchPicker
                        color={window.RGBAToHexA(
                          this.state.activeEdit.r,
                          this.state.activeEdit.g,
                          this.state.activeEdit.b,
                          this.state.activeEdit.a
                        )}
                        onChangeComplete={(e) => {
                          this.setState({
                              activeEdit: {
                                ...this.state.activeEdit,
                                r: e.rgb.r,
                                g: e.rgb.g,
                                b: e.rgb.b,
                                a: e.rgb.a,
                              }
                          });
                          this.applyMakeupFromState();
                        }}
                      />
                    </PopOver>
                  )}
                </Table.Cell>
                {this.state.showLipEffectType && (
                  <Table.Cell>
                    <Dropdown
                      fluid
                      scrolling
                      selection
                      defaultValue={this.state.activeEdit.lipEffectType}
                      ref={this.leDropdownRef}
                      onChange={(e, d) => {
                        this.setState({
                          activeEdit: {
                            ...this.state.activeEdit,
                            lipEffectType: d.value,
                          },
                        });
                        this.applyMakeupFromState();
                      }}
                      options={LipEffects}
                    />
                  </Table.Cell>
                )}
                {this.state.showFoundationEffectType && (
                  <Table.Cell>
                    <Dropdown
                      fluid
                      scrolling
                      selection
                      defaultValue={this.state.activeEdit.foundationEffectType}
                      ref={this.foDropdownRef}
                      onChange={(e, d) => {
                        this.setState({
                          activeEdit: {
                            ...this.state.activeEdit,
                            foundationEffectType: d.value,
                          },
                        });
                        this.applyMakeupFromState();
                      }}
                      options={FoundationEffects}
                    />
                  </Table.Cell>
                )}
                {this.state.showLipEffectIntensity && (
                  <Table.Cell collapsing>
                    <Slider
                      ref={this.leIntensityRef}
                      defaultValue={this.state.activeEdit.lipEffectValue}
                      min={0}
                      max={100}
                      step={5}
                      handle={handle}
                      onAfterChange={(val) => {
                        this.setState({
                          activeEdit: {
                            ...this.state.activeEdit,
                            lipEffectValue: val,
                          },
                        });
                        this.applyMakeupFromState();
                      }}
                    />
                  </Table.Cell>
                )}
                {this.state.showFoundationIntensity && (
                  <Table.Cell collapsing>
                    <Slider
                      ref={this.foIntensityRef}
                      defaultValue={this.state.activeEdit.foundationEffectValue}
                      min={0}
                      max={100}
                      step={5}
                      handle={handle}
                      onAfterChange={(val) => {
                        this.setState({
                          activeEdit: {
                            ...this.state.activeEdit,
                            foundationEffectValue: val,
                          },
                        });
                        this.applyMakeupFromState();
                      }}
                    />
                  </Table.Cell>
                )}
                {this.state.showFoundationSmoothingCoefficent && (
                  <Table.Cell collapsing>
                    <Slider
                      ref={this.foSmooothingCoefficentRef}
                      defaultValue={
                        this.state.activeEdit.foundationSmoothingCoefficentValue
                      }
                      min={0}
                      max={100}
                      step={5}
                      handle={handle}
                      onAfterChange={(val) => {
                        this.setState({
                          activeEdit: {
                            ...this.state.activeEdit,
                            foundationSmoothingCoefficentValue: val,
                          },
                        });
                        this.applyMakeupFromState();
                      }}
                    />
                  </Table.Cell>
                )}
                {this.state.showFoundationShininess && (
                  <Table.Cell collapsing>
                    <Slider
                      ref={this.foShininessRef}
                      defaultValue={this.state.activeEdit.foundationShininessValue}
                      min={0}
                      max={100}
                      step={5}
                      handle={handle}
                      onAfterChange={(val) => {
                        this.setState({ 
                          activeEdit: {
                            ...this.state.activeEdit,
                            foundationShininessValue: val,
                          },
                         });
                        this.applyMakeupFromState();
                      }}
                    />
                  </Table.Cell>
                )}
                {this.state.showFoundationBlendingRatio && (
                  <Table.Cell collapsing>
                    <Slider
                      ref={this.foBlendingRatioRef}
                      defaultValue={this.state.activeEdit.foundationBlendingRatioValue}
                      min={0}
                      max={100}
                      step={5}
                      handle={handle}
                      onAfterChange={(val) => {
                        this.setState({ 
                          activeEdit: {
                            ...this.state.activeEdit,
                            foundationBlendingRatioValue: val,
                          },
                         });
                        this.applyMakeupFromState();
                      }}
                    />
                  </Table.Cell>
                )}
                {this.state.showFoundationMaskIntensity && (
                  <Table.Cell collapsing>
                    <Slider
                      ref={this.foMaskIntensityRef}
                      defaultValue={this.state.activeEdit.foundationMaskIntensityValue}
                      min={0}
                      max={100}
                      step={5}
                      handle={handle}
                      onAfterChange={(val) => {
                        this.setState({ 
                          activeEdit: {
                            ...this.state.activeEdit,
                            foundationMaskIntensityValue: val,
                          },
                         });
                        this.applyMakeupFromState();
                      }}
                    />
                  </Table.Cell>
                )}
                {Object.keys(GlitterMasks).indexOf(this.props.itemTitle) !=
                  -1 && (
                    <Table.Cell>
                      <Dropdown
                        className="mask-selection-dropdown"
                        ref={this.glitterMaskDropdownRef}
                        onChange={(e, d) => {
                          this.setState({
                            activeEdit: {
                              ...this.state.activeEdit,
                              selectedGlitterMask: d.value,
                            },
                          });
                          this.applyMakeupFromState();
                        }}
                        fluid
                        scrolling
                        selection
                        defaultValue={this.state.activeEdit.selectedGlitterMask}
                        options={GlitterMasks[this.props.itemTitle]}
                      />
                    </Table.Cell>
                  )}
                {Object.keys(GlitterMasks).indexOf(this.props.itemTitle) !=
                  -1 && (
                    <Table.Cell collapsing>
                      <Slider
                        ref={this.glitterIntensityRef}
                        defaultValue={this.state.activeEdit.glitterIntensityValue}
                        min={0}
                        max={100}
                        step={5}
                        handle={handle}
                        onAfterChange={(val) => {
                          this.setState({
                            activeEdit: {
                              ...this.state.activeEdit,
                              glitterIntensityValue: val,
                            },
                          });
                          this.applyMakeupFromState();
                        }}
                      />
                    </Table.Cell>
                  )}
                {Object.keys(ShimmerMasks).indexOf(this.props.itemTitle) !=
                  -1 && (
                    <Table.Cell>
                      <Dropdown
                        className="mask-selection-dropdown"
                        ref={this.shimmerMaskDropdownRef}
                        onChange={(e, d) => {
                          this.setState({
                            activeEdit: {
                              ...this.state.activeEdit,
                              selectedShimmerMask: d.value,
                            },
                          });
                          this.applyMakeupFromState();
                        }}
                        fluid
                        scrolling
                        selection
                        defaultValue={this.state.activeEdit.selectedShimmerMask}
                        options={ShimmerMasks[this.props.itemTitle]}
                      />
                    </Table.Cell>
                  )}
                {Object.keys(ShimmerMasks).indexOf(this.props.itemTitle) !=
                  -1 && (
                    <Table.Cell collapsing>
                      <Slider
                        ref={this.shimmerIntensityRef}
                        defaultValue={this.state.activeEdit.shimmerIntensityValue}
                        min={0}
                        max={100}
                        step={5}
                        handle={handle}
                        onAfterChange={(val) => {
                          this.setState({
                            activeEdit: {
                              ...this.state.activeEdit,
                              shimmerIntensityValue: val,
                            },
                          });
                          this.applyMakeupFromState();
                        }}
                      />
                    </Table.Cell>
                  )}
                  {this.state.showBlushShimmerColor && (
                  <Table.Cell id="color-picker" collapsing>
                  <CustomButton size="mini" onClick={this.showProductEditColorPicker}>
                    <SelectColorText>Select Color</SelectColorText>
                    <ColorIndicator
                      style={{
                        backgroundColor: `${this.getBackgroundColor()}`,
                      }}
                    ></ColorIndicator>
                  </CustomButton>
                  {this.state.isProductEditColorPickerActive && (
                    <PopOver>
                      {false && (
                        <Cover
                          onClick={() =>
                            this.setState({
                              activeEdit: {
                                ...this.state.activeEdit,
                              },
                              isProductEditColorPickerActive: false,
                            })
                          }
                        ></Cover>
                      )}
                      <SketchPicker
                        color={window.RGBAToHexA(
                          this.state.activeEdit.shimmer_r,
                          this.state.activeEdit.shimmer_g,
                          this.state.activeEdit.shimmer_b,
                        )}
                        onChangeComplete={(e) => {
                          this.setState({
                              activeEdit: {
                                ...this.state.activeEdit,
                                shimmer_r: e.rgb.r,
                                shimmer_g: e.rgb.g,
                                shimmer_b: e.rgb.b,
                                
                              }
                          });
                          this.applyMakeupFromState();
                        }}
                      />
                    </PopOver>
                  )}
                </Table.Cell>
                )}
                {this.state.showEyeshadowShimmerColor && (
                <Table.Cell id="color-picker" collapsing>
                <CustomButton size="mini" onClick={this.showProductEditColorPicker}>
                  <SelectColorText>Select Color</SelectColorText>
                  <ColorIndicator
                    style={{
                      backgroundColor: `${this.getBackgroundColor()}`,
                    }}
                  ></ColorIndicator>
                </CustomButton>
                {this.state.isProductEditColorPickerActive && (
                  <PopOver>
                    {false && (
                      <Cover
                        onClick={() =>
                          this.setState({
                            activeEdit: {
                              ...this.state.activeEdit,
                            },
                            isProductEditColorPickerActive: false,
                          })
                        }
                      ></Cover>
                    )}
                    <SketchPicker
                      color={window.RGBAToHexA(
                        this.state.activeEdit.shimmer_r,
                        this.state.activeEdit.shimmer_g,
                        this.state.activeEdit.shimmer_b,
                        this.state.activeEdit.shimmer_a,
                      )}
                      onChangeComplete={(e) => {
                        this.setState({
                            activeEdit: {
                              ...this.state.activeEdit,
                              shimmer_r: e.rgb.r,
                              shimmer_g: e.rgb.g,
                              shimmer_b: e.rgb.b,
                              shimmer_a: e.rgb.a,                              
                            }
                        });
                        this.applyMakeupFromState();
                      }}
                    />
                  </PopOver>
                )}
              </Table.Cell>
              )}
                <Table.Cell collapsing singleLine>
                  <Button
                    onClick={this.updateProduct}
                    size="mini"
                    icon
                    labelPosition="left"
                  >
                    <Icon name="add" />
                    Update
                  </Button>
                  <Button
                    onClick={this.cancelUpdate}
                    size="mini"
                    icon
                    labelPosition="left"
                  >
                    <Icon name="cancel" />
                    Cancel
                  </Button>
                  <Button
                    onClick={this.clearRender}
                    size="mini"
                    icon
                    labelPosition="left"
                  >
                    <Icon name="close" />
                    Clear
                  </Button>
                </Table.Cell>
              </Table.Row>
            ) : (
              this.productsMayHaveMultipleFeatures() ?
              <Table.Row>
                <Table.Cell colSpan="5">
                  <Table textAlign="center" verticalAlign="middle" compact>
                    <Accordion.Accordion fluid styled>
                      <Accordion.Title className={`${(0 === 0) ? 'products-menu-title-height-active' : 'products-menu-title-height-inactive'}`}>
                        {item.sku_name}
                        <Icon name='dropdown' />
                      </Accordion.Title>
                      <Accordion.Content active={0 === 0}>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>ID</Table.HeaderCell>
                            <Table.HeaderCell>SKU ID</Table.HeaderCell>
                            <Table.HeaderCell>SKU Name</Table.HeaderCell>
                            {this.state.showMask && <Table.HeaderCell>Mask</Table.HeaderCell>}
                            <Table.HeaderCell>Color</Table.HeaderCell>
                            {this.state.showLipEffectType && (
                              <Table.HeaderCell>Finish</Table.HeaderCell>
                            )}
                            {this.state.showFoundationEffectType && (
                              <Table.HeaderCell>Finish</Table.HeaderCell>
                            )}
                            {this.state.showLipEffectIntensity && (
                              <Table.HeaderCell>Finish Intensity</Table.HeaderCell>
                            )}
                            {this.state.showFoundationIntensity && (
                              <Table.HeaderCell>Finish Intensity</Table.HeaderCell>
                            )}
                            {this.state.showFoundationSmoothingCoefficent && (
                              <Table.HeaderCell>Smoothing Coefficent</Table.HeaderCell>
                            )}
                            {this.state.showFoundationShininess && (
                              <Table.HeaderCell>Shininess</Table.HeaderCell>
                            )}
                            {this.state.showFoundationBlendingRatio && (
                              <Table.HeaderCell>Blending Ratio</Table.HeaderCell>
                            )}
                            {this.state.showFoundationMaskIntensity && (
                              <Table.HeaderCell>Mask Intensity</Table.HeaderCell>
                            )}
                            {Object.keys(GlitterMasks).indexOf(this.props.itemTitle) != -1 && (
                              <Table.HeaderCell>Glitter Mask</Table.HeaderCell>
                            )}
                            {Object.keys(GlitterMasks).indexOf(this.props.itemTitle) != -1 && (
                              <Table.HeaderCell>Glitter Intensity</Table.HeaderCell>
                            )}
                            {Object.keys(ShimmerMasks).indexOf(this.props.itemTitle) != -1 && (
                              <Table.HeaderCell>Shimmer Mask</Table.HeaderCell>
                            )}
                            {Object.keys(ShimmerMasks).indexOf(this.props.itemTitle) != -1 && (
                              <Table.HeaderCell>Shimmer Intensity</Table.HeaderCell>
                            )}
                            {this.state.showBlushShimmerColor && (
                              <Table.HeaderCell>Shimmer Color</Table.HeaderCell>
                            )}
                            {this.state.showEyeshadowShimmerColor && (
                              <Table.HeaderCell>Shimmer Color</Table.HeaderCell>
                            )}
                         
                            <Table.HeaderCell>Modify</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <CustomTableBody>
                          {
                            item.product_features.map((feature, index) => 
                            
                            this.state.activeEdit?.idx === feature.idx && this.state.activeEdit?.prd_idx === item.idx ? 
                            <Table.Row>
                <Table.Cell collapsing>{feature.idx}</Table.Cell>
                <Table.Cell>
                  <Input
                    className="sku-id-input"
                    value={this.state.activeEdit.skuId}
                    onChange={(e) => {
                      this.setState({
                        activeEdit: {
                          ...this.state.activeEdit,
                          skuId: e.target.value,
                        },
                      });
                    }}
                    fluid
                  />
                </Table.Cell>
                <Table.Cell>
                  <Input
                    className="sku-name-input"
                    value={this.state.activeEdit.skuName}
                    onChange={(e) => {
                      this.setState({
                        activeEdit: {
                          ...this.state.activeEdit,
                          skuName: e.target.value,
                        },
                      });
                    }}
                    fluid
                    placeholder={`${this.props.itemTitle.toUpperCase()}_${this.getIndex()}`}
                  />
                </Table.Cell>
                {this.state.showMask && (
                  <Table.Cell>
                    <Dropdown
                      className="mask-selection-dropdown"
                      value={this.state.activeEdit.selectedMask}
                      ref={this.maskDropdownRef}
                      onChange={(e, d) => {
                        this.setState({
                          activeEdit: {
                            ...this.state.activeEdit,
                            selectedMask: d.value,
                          },
                        });
                        this.applyMakeupFromState();
                      }}
                      fluid
                      scrolling
                      selection
                      defaultValue={this.state.activeEdit.selectedMask}
                      options={Masks[this.props.itemTitle]}
                    />
                  </Table.Cell>
                )}
                <Table.Cell id="color-picker" collapsing>
                  <CustomButton size="mini" onClick={this.showFeatureEditColorPicker}>
                    <SelectColorText>Select Color</SelectColorText>
                    <ColorIndicator
                      style={{
                        backgroundColor: `${this.getBackgroundColor()}`,
                      }}
                    ></ColorIndicator>
                  </CustomButton>
                  {this.state.isColorFeatureEditPickerActive && (
                    <PopOver>
                      {false && (
                        <Cover
                          onClick={() =>
                            this.setState({
                              activeEdit: {
                                ...this.state.activeEdit,
                              },
                              isColorFeatureEditPickerActive: false,
                            })
                          }
                        ></Cover>
                      )}
                      <SketchPicker
                        color={window.RGBAToHexA(
                          this.state.activeEdit.r,
                          this.state.activeEdit.g,
                          this.state.activeEdit.b,
                          this.state.activeEdit.a
                        )}
                        onChangeComplete={(e) => {
                          this.setState({
                              activeEdit: {
                                  ...this.state.activeEdit,
                                  r: e.rgb.r,
                                  g: e.rgb.g,
                                  b: e.rgb.b,
                                  a: e.rgb.a,
                              }
                          }, ()=>this.applyMakeupFromState())}}
                      />
                    </PopOver>
                  )}
                </Table.Cell>
                {this.state.showLipEffectType && (
                  <Table.Cell>
                    <Dropdown
                      fluid
                      scrolling
                      selection
                      defaultValue={this.state.activeEdit.lipEffectType}
                      ref={this.leDropdownRef}
                      onChange={(e, d) => {
                        this.setState({
                          activeEdit: {
                            ...this.state.activeEdit,
                            lipEffectType: d.value,
                          },
                        });
                        this.applyMakeupFromState();
                      }}
                      options={LipEffects}
                    />
                  </Table.Cell>
                )}
                {this.state.showFoundationEffectType && (
                  <Table.Cell>
                    <Dropdown
                      fluid
                      scrolling
                      selection
                      defaultValue={this.state.activeEdit.foundationEffectType}
                      ref={this.foDropdownRef}
                      onChange={(e, d) => {
                        this.setState({
                          activeEdit: {
                            ...this.state.activeEdit,
                            foundationEffectType: d.value,
                          },
                        });
                        this.applyMakeupFromState();
                      }}
                      options={FoundationEffects}
                    />
                  </Table.Cell>
                )}
                {this.state.showLipEffectIntensity && (
                  <Table.Cell collapsing>
                    <Slider
                      ref={this.leIntensityRef}
                      defaultValue={this.state.activeEdit.lipEffectValue}
                      min={0}
                      max={100}
                      step={5}
                      handle={handle}
                      onAfterChange={(val) => {
                        this.setState({
                          activeEdit: {
                            ...this.state.activeEdit,
                            lipEffectValue: val,
                          },
                        });
                        this.applyMakeupFromState();
                      }}
                    />
                  </Table.Cell>
                )}
                {this.state.showFoundationIntensity && (
                  <Table.Cell collapsing>
                    <Slider
                      ref={this.foIntensityRef}
                      defaultValue={this.state.activeEdit.foundationEffectValue}
                      min={0}
                      max={100}
                      step={5}
                      handle={handle}
                      onAfterChange={(val) => {
                        this.setState({
                          activeEdit: {
                            ...this.state.activeEdit,
                            foundationEffectValue: val,
                          },
                        });
                        this.applyMakeupFromState();
                      }}
                    />
                  </Table.Cell>
                )}
                {this.state.showFoundationSmoothingCoefficent && (
                  <Table.Cell collapsing>
                    <Slider
                      ref={this.foSmooothingCoefficentRef}
                      defaultValue={
                        this.state.activeEdit.foundationShininessValue
                      }
                      min={0}
                      max={100}
                      step={5}
                      handle={handle}
                      onAfterChange={(val) => {
                        this.setState({
                          activeEdit: {
                            ...this.state.activeEdit,
                            foundationShininessValue: val,
                          },
                        });
                        this.applyMakeupFromState();
                      }}
                    />
                  </Table.Cell>
                )}
                {this.state.showFoundationShininess && (
                  <Table.Cell collapsing>
                    <Slider
                      ref={this.foShininessRef}
                      defaultValue={100}
                      min={0}
                      max={100}
                      step={5}
                      handle={handle}
                      onAfterChange={(val) => {
                        this.setState({ 
                          activeEdit: {
                            ...this.state.activeEdit,
                            foundationShininessValue: val,
                          },
                         });
                        this.applyMakeupFromState();
                      }}
                    />
                  </Table.Cell>
                )}
                {this.state.showFoundationBlendingRatio && (
                  <Table.Cell collapsing>
                    <Slider
                      ref={this.foBlendingRatioRef}
                      defaultValue={100}
                      min={0}
                      max={100}
                      step={5}
                      handle={handle}
                      onAfterChange={(val) => {
                        this.setState({ 
                          activeEdit: {
                            ...this.state.activeEdit,
                            foundationBlendingRatioValue: val,
                          },
                         });
                        this.applyMakeupFromState();
                      }}
                    />
                  </Table.Cell>
                )}
                {this.state.showFoundationMaskIntensity && (
                  <Table.Cell collapsing>
                    <Slider
                      ref={this.foMaskIntensityRef}
                      defaultValue={100}
                      min={0}
                      max={100}
                      step={5}
                      handle={handle}
                      onAfterChange={(val) => {
                        this.setState({ 
                          activeEdit: {
                            ...this.state.activeEdit,
                            foundationMaskIntensityValue: val,
                          },
                         });
                        this.applyMakeupFromState();
                      }}
                    />
                  </Table.Cell>
                )}
                {Object.keys(GlitterMasks).indexOf(this.props.itemTitle) !=
                  -1 && (
                    <Table.Cell>
                      <Dropdown
                        className="mask-selection-dropdown"
                        ref={this.glitterMaskDropdownRef}
                        onChange={(e, d) => {
                          this.setState({
                            activeEdit: {
                              ...this.state.activeEdit,
                              selectedGlitterMask: d.value,
                            },
                          });
                          this.applyMakeupFromState();
                        }}
                        fluid
                        scrolling
                        selection
                        defaultValue={this.state.activeEdit.selectedGlitterMask}
                        options={GlitterMasks[this.props.itemTitle]}
                      />
                    </Table.Cell>
                  )}
                {Object.keys(GlitterMasks).indexOf(this.props.itemTitle) !=
                  -1 && (
                    <Table.Cell collapsing>
                      <Slider
                        ref={this.glitterIntensityRef}
                        defaultValue={this.state.activeEdit.glitterIntensityValue}
                        min={0}
                        max={100}
                        step={5}
                        handle={handle}
                        onAfterChange={(val) => {
                          this.setState({
                            activeEdit: {
                              ...this.state.activeEdit,
                              glitterIntensityValue: val,
                            },
                          });
                          this.applyMakeupFromState();
                        }}
                      />
                    </Table.Cell>
                  )}
                {Object.keys(ShimmerMasks).indexOf(this.props.itemTitle) !=
                  -1 && (
                    <Table.Cell>
                      <Dropdown
                        className="mask-selection-dropdown"
                        ref={this.shimmerMaskDropdownRef}
                        onChange={(e, d) => {
                          this.setState({
                            activeEdit: {
                              ...this.state.activeEdit,
                              selectedShimmerMask: d.value,
                            },
                          });
                          this.applyMakeupFromState();
                        }}
                        fluid
                        scrolling
                        selection
                        defaultValue={this.state.activeEdit.selectedShimmerMask}
                        options={ShimmerMasks[this.props.itemTitle]}
                      />
                    </Table.Cell>
                  )}
                {Object.keys(ShimmerMasks).indexOf(this.props.itemTitle) !=
                  -1 && (
                    <Table.Cell collapsing>
                      <Slider
                        ref={this.shimmerIntensityRef}
                        defaultValue={this.state.activeEdit.shimmerIntensityValue}
                        min={0}
                        max={100}
                        step={5}
                        handle={handle}
                        onAfterChange={(val) => {
                          this.setState({
                            activeEdit: {
                              ...this.state.activeEdit,
                              shimmerIntensityValue: val,
                            },
                          });
                          this.applyMakeupFromState();
                        }}
                      />
                    </Table.Cell>
                  )}
                  {this.state.showBlushShimmerColor && (
                  <Table.Cell id="color-picker" collapsing>
                  <CustomButton size="mini" onClick={this.showFeatureEditColorPicker}>
                    <SelectColorText>Select Color</SelectColorText>
                    <ColorIndicator
                      style={{
                        backgroundColor: `${this.getBackgroundColor()}`,
                      }}
                    ></ColorIndicator>
                  </CustomButton>
                  {this.state.isColorFeatureEditPickerActive && (
                    <PopOver>
                      {false && (
                        <Cover
                          onClick={() =>
                            this.setState({
                              activeEdit: {
                                ...this.state.activeEdit,
                              },
                              isColorFeatureEditPickerActive: false,
                            })
                          }
                        ></Cover>
                      )}
                      <SketchPicker
                        color={window.RGBAToHexA(
                          this.state.activeEdit.shimmer_r,
                          this.state.activeEdit.shimmer_g,
                          this.state.activeEdit.shimmer_b
                          
                        )}
                        onChangeComplete={(e) => {
                          this.setState({
                              activeEdit: {
                                  ...this.state.activeEdit,
                                  shimmer_r: e.rgb.r,
                                  shimmer_g: e.rgb.g,
                                  shimmer_b: e.rgb.b
                                  
                              }
                          }, ()=>this.applyMakeupFromState())}}
                      />
                    </PopOver>
                  )}
                </Table.Cell>
                )}
                {this.state.showEyeshadowShimmerColor && (
                <Table.Cell id="color-picker" collapsing>
                <CustomButton size="mini" onClick={this.showFeatureEditColorPicker}>
                  <SelectColorText>Select Color</SelectColorText>
                  <ColorIndicator
                    style={{
                      backgroundColor: `${this.getBackgroundColor()}`,
                    }}
                  ></ColorIndicator>
                </CustomButton>
                {this.state.isColorFeatureEditPickerActive && (
                  <PopOver>
                    {false && (
                      <Cover
                        onClick={() =>
                          this.setState({
                            activeEdit: {
                              ...this.state.activeEdit,
                            },
                            isColorFeatureEditPickerActive: false,
                          })
                        }
                      ></Cover>
                    )}
                    <SketchPicker
                      color={window.RGBAToHexA(
                        this.state.activeEdit.shimmer_r,
                        this.state.activeEdit.shimmer_g,
                        this.state.activeEdit.shimmer_b,
                        this.state.activeEdit.shimmer_a
                        
                      )}
                      onChangeComplete={(e) => {
                        this.setState({
                            activeEdit: {
                                ...this.state.activeEdit,
                                shimmer_r: e.rgb.r,
                                shimmer_g: e.rgb.g,
                                shimmer_b: e.rgb.b,
                                shimmer_a: e.rgb.a                                
                            }
                        }, ()=>this.applyMakeupFromState())}}
                    />
                  </PopOver>
                )}
              </Table.Cell>
              )}
                <Table.Cell collapsing singleLine>
                  <Button
                    onClick={this.updateProductFeature}
                    size="mini"
                    icon
                    labelPosition="left"
                  >
                    <Icon name="add" />
                    Update
                  </Button>
                  <Button
                    onClick={this.cancelUpdate}
                    size="mini"
                    icon
                    labelPosition="left"
                  >
                    <Icon name="cancel" />
                    Cancel
                  </Button>
                  <Button
                    onClick={this.clearRender}
                    size="mini"
                    icon
                    labelPosition="left"
                  >
                    <Icon name="close" />
                    Clear
                  </Button>
                </Table.Cell>
              </Table.Row>
                            :
                            <Table.Row>
                              <Table.Cell collapsing>{feature.idx}</Table.Cell>
                              <Table.Cell>{feature.sku_id}</Table.Cell>
                              <Table.Cell>{feature.sku_name}</Table.Cell>

                              {this.state.showMask && (
                                <Table.Cell
                                  textAlign="center"
                                  verticalAlign="middle"
                                  collapsing
                                >
                                  {
                                    Masks[window.capitalize(feature.type.toLowerCase())].filter(
                                      (obj) => obj.name == feature.mask
                                    )[0].text
                                  }
                                </Table.Cell>
                              )}

                              <Table.Cell
                                title={`rgba(${feature.r}, ${feature.g}, ${feature.b}, ${feature.a})`}
                                textAlign="center"
                                verticalAlign="middle"
                                collapsing
                              >
                                <ColorIndicator
                                  style={{
                                    backgroundColor: `rgba(${feature.r}, ${feature.g}, ${feature.b}, ${feature.a})`,
                                  }}
                                ></ColorIndicator>
                              </Table.Cell>

                              {this.state.showLipEffectType && (
                                <Table.Cell
                                  textAlign="center"
                                  verticalAlign="middle"
                                  collapsing
                                >
                                  {
                                    LipEffects.filter((obj) => {
                                      return obj.value == feature.finish_type;
                                    })[0].text
                                  }
                                </Table.Cell>
                              )}
                              {this.state.showFoundationEffectType && (
                                <Table.Cell
                                  textAlign="center"
                                  verticalAlign="middle"
                                  collapsing
                                >
                                  {
                                    FoundationEffects.filter((obj) => {
                                      return obj.value == feature.finish_type;
                                    })[0].text
                                  }
                                </Table.Cell>
                              )}
                              {this.state.showLipEffectIntensity && (
                                <Table.Cell
                                  textAlign="center"
                                  verticalAlign="middle"
                                  collapsing
                                >
                                  {feature.finish_intensity}
                                </Table.Cell>
                              )}
                              {this.state.showFoundationIntensity && (
                                <Table.Cell
                                  textAlign="center"
                                  verticalAlign="middle"
                                  collapsing
                                >
                                  {feature.finish_intensity}
                                </Table.Cell>
                              )}
                              {this.state.showFoundationSmoothingCoefficent && (
                                <Table.Cell
                                  textAlign="center"
                                  verticalAlign="middle"
                                  collapsing
                                >
                                  {feature.smoothing_coefficent}
                                </Table.Cell>
                              )}
                              {this.state.showFoundationShininess && (
                                <Table.Cell
                                  textAlign="center"
                                  verticalAlign="middle"
                                  collapsing
                                >
                                  {feature.shininess}
                                </Table.Cell>
                              )}
                              {this.state.showFoundationBlendingRatio && (
                                <Table.Cell
                                  textAlign="center"
                                  verticalAlign="middle"
                                  collapsing
                                >
                                  {feature.blending_ratio}
                                </Table.Cell>
                              )}
                              {this.state.showFoundationMaskIntensity && (
                                <Table.Cell
                                  textAlign="center"
                                  verticalAlign="middle"
                                  collapsing
                                >
                                  {feature.mask_intensity}
                                </Table.Cell>
                              )}
                              {Object.keys(GlitterMasks).indexOf(this.props.itemTitle) !=
                                -1 && (
                                  <Table.Cell
                                    textAlign="center"
                                    verticalAlign="middle"
                                    collapsing
                                  >
                                    {feature.hasOwnProperty("glitter_intensity") &&
                                      feature.glitter_intensity > 0
                                      ? GlitterMasks[this.props.itemTitle].filter(
                                        (obj) => obj.name == feature.glitter_mask
                                      )[0].text
                                      : "NA"}
                                  </Table.Cell>
                                )}
                              {Object.keys(GlitterMasks).indexOf(this.props.itemTitle) !=
                                -1 && (
                                  <Table.Cell
                                    textAlign="center"
                                    verticalAlign="middle"
                                    collapsing
                                  >
                                    {feature.hasOwnProperty("glitter_intensity") &&
                                      feature.glitter_intensity > 0
                                      ? feature.glitter_intensity
                                      : "NA"}
                                  </Table.Cell>
                                )}
                              {Object.keys(ShimmerMasks).indexOf(this.props.itemTitle) !=
                                -1 && (
                                  <Table.Cell
                                    textAlign="center"
                                    verticalAlign="middle"
                                    collapsing
                                  >
                                    {feature.hasOwnProperty("shimmer_intensity") &&
                                      feature.shimmer_intensity > 0
                                      ? ShimmerMasks[this.props.itemTitle].filter(
                                        (obj) => obj.name == feature.shimmer_mask
                                      )[0].text
                                      : "NA"}
                                  </Table.Cell>
                                )}
                              {Object.keys(ShimmerMasks).indexOf(this.props.itemTitle) !=
                                -1 && (
                                  <Table.Cell
                                    textAlign="center"
                                    verticalAlign="middle"
                                    collapsing
                                  >
                                    {feature.hasOwnProperty("shimmer_intensity") &&
                                      feature.shimmer_intensity > 0
                                      ? feature.shimmer_intensity
                                      : "NA"}
                                  </Table.Cell>
                                )}
                              <Table.Cell collapsing singleLine>

                                <Button
                                  toggle
                                  size="mini"
                                  icon="edit"
                                  content="Edit Feature"
                                  onClick={(e) => this.editProductFeature(item.idx, feature)}
                                />
                                <DoubleClickButton toRemove={{...feature, prd_idx: item.idx}} />
                              </Table.Cell>
                            </Table.Row>
                            )}
                          <Table.Row>
                            <Table.Cell collapsing>{item.product_features.length}</Table.Cell>
                            
                            <Table.Cell>
                              <Input
                                className="sku-id-input"
                                onChange={(e) => {
                                  this.setState({ skuId: e.target.value });
                                }}
                                fluid
                                placeholder={`${this.props.itemTitle.toUpperCase()}_${this.getIndex()}`}
                              />
                            </Table.Cell>
                            <Table.Cell>
                              <Input
                                className="sku-name-input"
                                onChange={(e) => {
                                  this.setState({ skuName: e.target.value });
                                }}
                                fluid
                                placeholder={`${this.props.itemTitle.toUpperCase()}_${this.getIndex()}`}
                              />
                            </Table.Cell>
                            {this.state.showMask && (
                              <Table.Cell>
                                <Dropdown
                                  className="mask-selection-dropdown"
                                  ref={this.maskDropdownRef}
                                  onChange={(e, d) => {
                                    this.setState({ selectedMask: d.value });
                                    this.applyMakeupFromState();
                                  }}
                                  fluid
                                  scrolling
                                  selection
                                  defaultValue={this.getDefaultMask()}
                                  options={Masks[this.props.itemTitle]}
                                />
                              </Table.Cell>
                            )}
                            <Table.Cell id="color-picker" collapsing>
                              <CustomButton size="mini" onClick={this.showFeatureAddColorPicker}>
                                <SelectColorText>Select Color</SelectColorText>
                                <ColorIndicator
                                  style={{ backgroundColor: `${this.getBackgroundColor()}` }}
                                ></ColorIndicator>
                              </CustomButton>
                              {this.state.isColorFeatureAddPickerActive && (
                                <PopOver>
                                  {false && (
                                    <Cover
                                      onClick={() =>
                                        this.setState({ isColorFeatureAddPickerActive: false })
                                      }
                                    ></Cover>
                                  )}
                                  <SketchPicker
                                    color={this.getBackgroundColor()}
                                    onChangeComplete={(e) => {
                                      this.setState({
                                        r: e.rgb.r,
                                        g: e.rgb.g,
                                        b: e.rgb.b,
                                        a: e.rgb.a,
                                      });
                                      this.applyMakeupFromState();
                                    }}
                                  />
                                </PopOver>
                              )}
                            </Table.Cell>
                            {this.state.showLipEffectType && (
                              <Table.Cell>
                                <Dropdown
                                  fluid
                                  scrolling
                                  selection
                                  defaultValue={0}
                                  ref={this.leDropdownRef}
                                  onChange={(e, d) => {
                                    this.setState({ lipEffectType: d.value });
                                    this.applyMakeupFromState();
                                  }}
                                  options={LipEffects}
                                />
                              </Table.Cell>
                            )}
                            {this.state.showFoundationEffectType && (
                              <Table.Cell>
                                <Dropdown
                                  fluid
                                  scrolling
                                  selection
                                  defaultValue={this.getDefaultFoundationFinishType()}
                                  ref={this.foDropdownRef}
                                  onChange={(e, d) => {
                                    this.setState({ foundationEffectType: d.value });
                                    this.applyMakeupFromState();
                                  }}
                                  options={FoundationEffects}
                                />
                              </Table.Cell>
                            )}
                            {this.state.showLipEffectIntensity && (
                              <Table.Cell collapsing>
                                <Slider
                                  ref={this.leIntensityRef}
                                  defaultValue={100}
                                  min={0}
                                  max={100}
                                  step={5}
                                  handle={handle}
                                  onAfterChange={(val) => {
                                    this.setState({ lipEffectValue: val });
                                    this.applyMakeupFromState();
                                  }}
                                />
                              </Table.Cell>
                            )}
                            {this.state.showFoundationIntensity && (
                              <Table.Cell collapsing>
                                <Slider
                                  ref={this.foIntensityRef}
                                  defaultValue={50}
                                  min={0}
                                  max={100}
                                  step={5}
                                  handle={handle}
                                  onAfterChange={(val) => {
                                    this.setState({ foundationEffectValue: val });
                                    this.applyMakeupFromState();
                                  }}
                                />
                              </Table.Cell>
                            )}
                            {this.state.showFoundationSmoothingCoefficent && (
                              <Table.Cell collapsing>
                                <Slider
                                  ref={this.foSmooothingCoefficentRef}
                                  defaultValue={50}
                                  min={0}
                                  max={100}
                                  step={5}
                                  handle={handle}
                                  onAfterChange={(val) => {
                                    this.setState({ foundationSmoothingCoefficentValue: val });
                                    this.applyMakeupFromState();
                                  }}
                                />
                              </Table.Cell>
                            )}
                            {this.state.showFoundationShininess && (
                              <Table.Cell collapsing>
                                <Slider
                                  ref={this.foShininessRef}
                                  defaultValue={50}
                                  min={0}
                                  max={100}
                                  step={5}
                                  handle={handle}
                                  onAfterChange={(val) => {
                                    this.setState({ foundationShininessValue: val });
                                    this.applyMakeupFromState();
                                  }}
                                />
                              </Table.Cell>
                            )}
                            {this.state.showFoundationBlendingRatio && (
                              <Table.Cell collapsing>
                                <Slider
                                  ref={this.foBlendingRatioRef}
                                  defaultValue={50}
                                  min={0}
                                  max={100}
                                  step={5}
                                  handle={handle}
                                  onAfterChange={(val) => {
                                    this.setState({ foundationBlendingRatioValue: val });
                                    this.applyMakeupFromState();
                                  }}
                                />
                              </Table.Cell>
                            )}
                            {this.state.showFoundationMaskIntensity && (
                              <Table.Cell collapsing>
                                <Slider
                                  ref={this.foMaskIntensityRef}
                                  defaultValue={50}
                                  min={0}
                                  max={100}
                                  step={5}
                                  handle={handle}
                                  onAfterChange={(val) => {
                                    this.setState({ foundationMaskIntensityValue: val });
                                    this.applyMakeupFromState();
                                  }}
                                />
                              </Table.Cell>
                            )}
                            {Object.keys(GlitterMasks).indexOf(this.props.itemTitle) != -1 && (
                              <Table.Cell>
                                <Dropdown
                                  className="mask-selection-dropdown"
                                  ref={this.glitterMaskDropdownRef}
                                  onChange={(e, d) => {
                                    this.setState({ selectedGlitterMask: d.value });
                                    this.applyMakeupFromState();
                                  }}
                                  fluid
                                  scrolling
                                  selection
                                  defaultValue={this.getDefaultGlitterMask()}
                                  options={GlitterMasks[this.props.itemTitle]}
                                />
                              </Table.Cell>
                            )}
                            {Object.keys(GlitterMasks).indexOf(this.props.itemTitle) != -1 && (
                              <Table.Cell collapsing>
                                <Slider
                                  ref={this.glitterIntensityRef}
                                  defaultValue={0}
                                  min={0}
                                  max={100}
                                  step={5}
                                  handle={handle}
                                  onAfterChange={(val) => {
                                    this.setState({ glitterIntensityValue: val });
                                    this.applyMakeupFromState();
                                  }}
                                />
                              </Table.Cell>
                            )}
                            {Object.keys(ShimmerMasks).indexOf(this.props.itemTitle) != -1 && (
                              <Table.Cell>
                                <Dropdown
                                  className="mask-selection-dropdown"
                                  ref={this.shimmerMaskDropdownRef}
                                  onChange={(e, d) => {
                                    this.setState({ selectedShimmerMask: d.value });
                                    this.applyMakeupFromState();
                                  }}
                                  fluid
                                  scrolling
                                  selection
                                  defaultValue={this.getDefaultShimmerMask()}
                                  options={ShimmerMasks[this.props.itemTitle]}
                                />
                              </Table.Cell>
                            )}
                            {Object.keys(ShimmerMasks).indexOf(this.props.itemTitle) != -1 && (
                              <Table.Cell collapsing>
                                <Slider
                                  ref={this.shimmerIntensityRef}
                                  defaultValue={0}
                                  min={0}
                                  max={100}
                                  step={5}
                                  handle={handle}
                                  onAfterChange={(val) => {
                                    this.setState({ shimmerIntensityValue: val });
                                    this.applyMakeupFromState();
                                  }}
                                />
                              </Table.Cell>
                            )}
                            {this.state.showBlushShimmerColor && (
                  <Table.Cell id="color-picker" collapsing>
                  <CustomButton size="mini" onClick={this.showFeatureEditColorPicker}>
                    <SelectColorText>Select Color</SelectColorText>
                    <ColorIndicator
                      style={{
                        backgroundColor: `${this.getBackgroundColor()}`,
                      }}
                    ></ColorIndicator>
                  </CustomButton>
                  {this.state.isColorFeatureEditPickerActive && (
                    <PopOver>
                      {false && (
                        <Cover
                          onClick={() =>
                            this.setState({
                              activeEdit: {
                                ...this.state.activeEdit,
                              },
                              isColorFeatureEditPickerActive: false,
                            })
                          }
                        ></Cover>
                      )}
                      <SketchPicker
                        color={window.RGBAToHexA(
                          this.state.activeEdit.shimmer_r,
                          this.state.activeEdit.shimmer_g,
                          this.state.activeEdit.shimmer_b,
                          
                        )}
                        onChangeComplete={(e) => {
                          this.setState({
                              activeEdit: {
                                  ...this.state.activeEdit,
                                  shimmer_r: e.rgb.r,
                                  shimmer_g: e.rgb.g,
                                  shimmer_b: e.rgb.b,
                                  
                              }
                          }, ()=>this.applyMakeupFromState())}}
                      />
                    </PopOver>
                  )}
                </Table.Cell>
                )}
                {this.state.showEyeshadowShimmerColor && (
                <Table.Cell id="color-picker" collapsing>
                <CustomButton size="mini" onClick={this.showFeatureEditColorPicker}>
                  <SelectColorText>Select Color</SelectColorText>
                  <ColorIndicator
                    style={{
                      backgroundColor: `${this.getBackgroundColor()}`,
                    }}
                  ></ColorIndicator>
                </CustomButton>
                {this.state.isColorFeatureEditPickerActive && (
                  <PopOver>
                    {false && (
                      <Cover
                        onClick={() =>
                          this.setState({
                            activeEdit: {
                              ...this.state.activeEdit,
                            },
                            isColorFeatureEditPickerActive: false,
                          })
                        }
                      ></Cover>
                    )}
                    <SketchPicker
                      color={window.RGBAToHexA(
                        this.state.activeEdit.shimmer_r,
                        this.state.activeEdit.shimmer_g,
                        this.state.activeEdit.shimmer_b,
                        this.state.activeEdit.shimmer_a,
                        
                      )}
                      onChangeComplete={(e) => {
                        this.setState({
                            activeEdit: {
                                ...this.state.activeEdit,
                                shimmer_r: e.rgb.r,
                                shimmer_g: e.rgb.g,
                                shimmer_b: e.rgb.b,
                                shimmer_a: e.rgb.a,                                
                            }
                        }, ()=>this.applyMakeupFromState())}}
                    />
                  </PopOver>
                )}
              </Table.Cell>
              )}
                            <Table.Cell collapsing singleLine>
                              <Button
                                onClick={e => this.addFeatureToProduct(item)}
                                size="mini"
                                icon
                                labelPosition="left"
                              >
                                <Icon name="add" />
                Add Feature
              </Button>
                              <Button
                                onClick={this.clearRender}
                                size="mini"
                                icon
                                labelPosition="left"
                              >
                                <Icon name="close" />
                Clear
              </Button>
                            </Table.Cell>
                          </Table.Row>

                        </CustomTableBody>
                      </Accordion.Content>
                    </Accordion.Accordion>
                  </Table>
                </Table.Cell>
                <Table.Cell collapsing singleLine>
                  <Button
                    toggle
                    size="mini"
                    active={
                      this.state.activeView == `${item.idx}+_${item.sku_id}`
                    }
                    icon="eye"
                    content="View"
                    onClick={(e) => {
                      this.handleMakeupToggle(item);
                    }}
                  />
                  <Button
                    toggle
                    size="mini"
                    icon="clone"
                    content="Clone"
                    onClick={(e) => this.cloneProduct(item)}
                  />
                  <DoubleClickButton toRemove={item} />
                </Table.Cell>
              </Table.Row>

              :

              
              <Table.Row>
                <Table.Cell collapsing>{item.idx}</Table.Cell>
                <Table.Cell>{item.sku_id}</Table.Cell>
                <Table.Cell>{item.sku_name}</Table.Cell>

                {this.state.showMask && (
                  <Table.Cell
                    textAlign="center"
                    verticalAlign="middle"
                    collapsing
                  >
                    {
                      Masks[window.capitalize(item.product_features[0].type.toLowerCase())].filter(
                        (obj) => obj.name == item.product_features[0].mask
                      )[0]?.text
                    }
                  </Table.Cell>
                )}

                <Table.Cell
                  title={`rgba(${item.product_features[0].r}, ${item.product_features[0].g}, ${item.product_features[0].b}, ${item.product_features[0].a})`}
                  textAlign="center"
                  verticalAlign="middle"
                  collapsing
                >
                  <ColorIndicator
                    style={{
                      backgroundColor: `rgba(${item.product_features[0].r}, ${item.product_features[0].g}, ${item.product_features[0].b}, ${item.product_features[0].a})`,
                    }}
                  ></ColorIndicator>
                </Table.Cell>

                {this.state.showLipEffectType && (
                  <Table.Cell
                    textAlign="center"
                    verticalAlign="middle"
                    collapsing
                  >
                    {
                      LipEffects.filter((obj) => {
                        return obj.value == item.product_features[0].finish_type;
                      })[0].text
                    }
                  </Table.Cell>
                )}
                {this.state.showFoundationEffectType && (
                  <Table.Cell
                    textAlign="center"
                    verticalAlign="middle"
                    collapsing
                  >
                    {
                      FoundationEffects.filter((obj) => {
                        return obj.value == item.product_features[0].finish_type;
                      })[0]?.text
                    }
                  </Table.Cell>
                )}
                {this.state.showLipEffectIntensity && (
                  <Table.Cell
                    textAlign="center"
                    verticalAlign="middle"
                    collapsing
                  >
                    {item.product_features[0].finish_intensity}
                  </Table.Cell>
                )}
                {this.state.showFoundationIntensity && (
                  <Table.Cell
                    textAlign="center"
                    verticalAlign="middle"
                    collapsing
                  >
                    {item.product_features[0].finish_intensity}
                  </Table.Cell>
                )}
                {this.state.showFoundationSmoothingCoefficent && (
                  <Table.Cell
                    textAlign="center"
                    verticalAlign="middle"
                    collapsing
                  >
                    {item.product_features[0].smoothing_coefficent}
                  </Table.Cell>
                )}
                {this.state.showFoundationShininess && (
                  <Table.Cell
                    textAlign="center"
                    verticalAlign="middle"
                    collapsing
                  >
                    {item.product_features[0].shininess}
                  </Table.Cell>
                )}
                {this.state.showFoundationBlendingRatio && (
                  <Table.Cell
                    textAlign="center"
                    verticalAlign="middle"
                    collapsing
                  >
                    {item.product_features[0].blending_ratio}
                  </Table.Cell>
                )}
                {this.state.showFoundationMaskIntensity && (
                  <Table.Cell
                    textAlign="center"
                    verticalAlign="middle"
                    collapsing
                  >
                    {item.product_features[0].mask_intensity}
                  </Table.Cell>
                )}
                {Object.keys(GlitterMasks).indexOf(this.props.itemTitle) !=
                  -1 && (
                  <Table.Cell
                    textAlign="center"
                    verticalAlign="middle"
                    collapsing
                  >
                    {item.product_features[0].hasOwnProperty("glitter_intensity") &&
                    item.product_features[0].glitter_intensity > 0
                      ? GlitterMasks[this.props.itemTitle].filter(
                          (obj) => obj.name == item.product_features[0].glitter_mask
                        )[0].text
                      : "NA"}
                  </Table.Cell>
                )}
                {Object.keys(GlitterMasks).indexOf(this.props.itemTitle) !=
                  -1 && (
                  <Table.Cell
                    textAlign="center"
                    verticalAlign="middle"
                    collapsing
                  >
                    {item.product_features[0].hasOwnProperty("glitter_intensity") &&
                    item.product_features[0].glitter_intensity > 0
                      ? item.product_features[0].glitter_intensity
                      : "NA"}
                  </Table.Cell>
                )}
                {Object.keys(ShimmerMasks).indexOf(this.props.itemTitle) !=
                  -1 && (
                  <Table.Cell
                    textAlign="center"
                    verticalAlign="middle"
                    collapsing
                  >
                    {item.product_features[0].hasOwnProperty("shimmer_intensity") &&
                    item.product_features[0].shimmer_intensity > 0
                      ? ShimmerMasks[this.props.itemTitle].filter(
                          (obj) => obj.name == item.product_features[0].shimmer_mask
                        )[0].text
                      : "NA"}
                  </Table.Cell>
                )}
                {Object.keys(ShimmerMasks).indexOf(this.props.itemTitle) !=
                  -1 && (
                  <Table.Cell
                    textAlign="center"
                    verticalAlign="middle"
                    collapsing
                  >
                    {item.product_features[0].hasOwnProperty("shimmer_intensity") &&
                    item.product_features[0].shimmer_intensity > 0
                      ? item.product_features[0].shimmer_intensity
                      : "NA"}
                  </Table.Cell>
                )}
                <Table.Cell collapsing singleLine>
                  <Button
                    toggle
                    size="mini"
                    active={
                      this.state.activeView == `${item.idx}+_${item.sku_id}`
                    }
                    icon="eye"
                    content="View"
                    onClick={(e) => {
                      this.handleMakeupToggle(item);
                    }}
                  />
                  <Button
                    toggle
                    size="mini"
                    icon="edit"
                    content="Edit"
                    onClick={(e) => this.editProduct(item)}
                  />
                  <DoubleClickButton toRemove={item} />
                </Table.Cell>
              </Table.Row>

            );
          })}
          
        {this.productsMayHaveMultipleFeatures() && 
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>SKU ID</Table.HeaderCell>
            <Table.HeaderCell>SKU Name</Table.HeaderCell>
            {this.state.showMask && <Table.HeaderCell>Mask</Table.HeaderCell>}
            <Table.HeaderCell>Color</Table.HeaderCell>
            {this.state.showLipEffectType && (
              <Table.HeaderCell>Finish</Table.HeaderCell>
            )}
            {this.state.showFoundationEffectType && (
              <Table.HeaderCell>Finish</Table.HeaderCell>
            )}
            {this.state.showLipEffectIntensity && (
              <Table.HeaderCell>Finish Intensity</Table.HeaderCell>
            )}
            {this.state.showFoundationIntensity && (
              <Table.HeaderCell>Finish Intensity</Table.HeaderCell>
            )}
            {this.state.showFoundationSmoothingCoefficent && (
              <Table.HeaderCell>Smoothing Coefficent</Table.HeaderCell>
            )}
            {this.state.showFoundationShininess && (
              <Table.HeaderCell>Shininess</Table.HeaderCell>
            )}
            {this.state.showFoundationBlendingRatio && (
              <Table.HeaderCell>Blending Ratio</Table.HeaderCell>
            )}
            {this.state.showFoundationMaskIntensity && (
              <Table.HeaderCell>Mask Intensity</Table.HeaderCell>
            )}
            {Object.keys(GlitterMasks).indexOf(this.props.itemTitle) != -1 && (
              <Table.HeaderCell>Glitter Mask</Table.HeaderCell>
            )}
            {Object.keys(GlitterMasks).indexOf(this.props.itemTitle) != -1 && (
              <Table.HeaderCell>Glitter Intensity</Table.HeaderCell>
            )}
            {Object.keys(ShimmerMasks).indexOf(this.props.itemTitle) != -1 && (
              <Table.HeaderCell>Shimmer Mask</Table.HeaderCell>
            )}
            {Object.keys(ShimmerMasks).indexOf(this.props.itemTitle) != -1 && (
              <Table.HeaderCell>Shimmer Intensity</Table.HeaderCell>
            )}
            {this.state.showBlushShimmerColor && (
              <Table.HeaderCell>Shimmer Color</Table.HeaderCell>
            )}
            {this.state.showEyeshadowShimmerColor && (
              <Table.HeaderCell>Shimmer Color</Table.HeaderCell>
            )}
		  
            <Table.HeaderCell>Modify</Table.HeaderCell>
          </Table.Row>}
          <Table.Row>
            <Table.Cell collapsing>{this.getIndex()}</Table.Cell>
            <Table.Cell>
              <Input
                className="sku-id-input"
                onChange={(e) => {
                  this.setState({ skuId: e.target.value });
                }}
                fluid
                placeholder={`${this.props.itemTitle.toUpperCase()}_${this.getIndex()}`}
              />
            </Table.Cell>
            <Table.Cell>
              <Input
                className="sku-name-input"
                onChange={(e) => {
                  this.setState({ skuName: e.target.value });
                }}
                fluid
                placeholder={`${this.props.itemTitle.toUpperCase()}_${this.getIndex()}`}
              />
            </Table.Cell>
            {this.state.showMask && (
              <Table.Cell>
                <Dropdown
                  className="mask-selection-dropdown"
                  ref={this.maskDropdownRef}
                  onChange={(e, d) => {
                    this.setState({ selectedMask: d.value });
                    this.applyMakeupFromState();
                  }}
                  fluid
                  scrolling
                  selection
                  defaultValue={this.getDefaultMask()}
                  options={Masks[this.props.itemTitle]}
                />
              </Table.Cell>
            )}
            <Table.Cell id="color-picker" collapsing>
              <CustomButton size="mini" onClick={this.showColorPicker}>
                <SelectColorText>Select Color</SelectColorText>
                <ColorIndicator
                  style={{ backgroundColor: `${this.getBackgroundColor()}` }}
                ></ColorIndicator>
              </CustomButton>
              {this.state.isColorPickerActive && (
                <PopOver>
                  {false && (
                    <Cover
                      onClick={() =>
                        this.setState({ isColorPickerActive: false })
                      }
                    ></Cover>
                  )}
                  <SketchPicker
                    color={this.getBackgroundColor()}
                    onChangeComplete={(e) => {
                      this.setState({
                        r: e.rgb.r,
                        g: e.rgb.g,
                        b: e.rgb.b,
                        a: e.rgb.a,
                      });
                      this.applyMakeupFromState();
                    }}
                  />
                </PopOver>
              )}
            </Table.Cell>
            {this.state.showLipEffectType && (
              <Table.Cell>
                <Dropdown
                  fluid
                  scrolling
                  selection
                  defaultValue={0}
                  ref={this.leDropdownRef}
                  onChange={(e, d) => {
                    this.setState({ lipEffectType: d.value });
                    this.applyMakeupFromState();
                  }}
                  options={LipEffects}
                />
              </Table.Cell>
            )}
            {this.state.showFoundationEffectType && (
              <Table.Cell>
                <Dropdown
                  fluid
                  scrolling
                  selection
                  defaultValue={this.getDefaultFoundationFinishType()}
                  ref={this.foDropdownRef}
                  onChange={(e, d) => {
                    this.setState({ foundationEffectType: d.value });
                    this.applyMakeupFromState();
                  }}
                  options={FoundationEffects}
                />
              </Table.Cell>
            )}
            {this.state.showLipEffectIntensity && (
              <Table.Cell collapsing>
                <Slider
                  ref={this.leIntensityRef}
                  defaultValue={100}
                  min={0}
                  max={100}
                  step={5}
                  handle={handle}
                  onAfterChange={(val) => {
                    this.setState({ lipEffectValue: val });
                    this.applyMakeupFromState();
                  }}
                />
              </Table.Cell>
            )}
            {this.state.showFoundationIntensity && (
              <Table.Cell collapsing>
                <Slider
                  ref={this.foIntensityRef}
                  defaultValue={50}
                  min={0}
                  max={100}
                  step={5}
                  handle={handle}
                  onAfterChange={(val) => {
                    this.setState({ foundationEffectValue: val });
                    this.applyMakeupFromState();
                  }}
                />
              </Table.Cell>
            )}
            {this.state.showFoundationSmoothingCoefficent && (
              <Table.Cell collapsing>
                <Slider
                  ref={this.foSmooothingCoefficentRef}
                  defaultValue={50}
                  min={0}
                  max={100}
                  step={5}
                  handle={handle}
                  onAfterChange={(val) => {
                    this.setState({ foundationSmoothingCoefficentValue: val });
                    this.applyMakeupFromState();
                  }}
                />
              </Table.Cell>
            )}
            {this.state.showFoundationShininess && (
              <Table.Cell collapsing>
                <Slider
                  ref={this.foShininessRef}
                  defaultValue={50}
                  min={0}
                  max={100}
                  step={5}
                  handle={handle}
                  onAfterChange={(val) => {
                    this.setState({ foundationShininessValue: val });
                    this.applyMakeupFromState();
                  }}
                />
              </Table.Cell>
            )}
            {this.state.showFoundationBlendingRatio && (
              <Table.Cell collapsing>
                <Slider
                  ref={this.foBlendingRatioRef}
                  defaultValue={50}
                  min={0}
                  max={100}
                  step={5}
                  handle={handle}
                  onAfterChange={(val) => {
                    this.setState({ foundationBlendingRatioValue: val });
                    this.applyMakeupFromState();
                  }}
                />
              </Table.Cell>
            )}
            {this.state.showFoundationMaskIntensity && (
              <Table.Cell collapsing>
                <Slider
                  ref={this.foMaskIntensityRef}
                  defaultValue={50}
                  min={0}
                  max={100}
                  step={5}
                  handle={handle}
                  onAfterChange={(val) => {
                    this.setState({ foundationMaskIntensityValue: val });
                    this.applyMakeupFromState();
                  }}
                />
              </Table.Cell>
            )}
            {Object.keys(GlitterMasks).indexOf(this.props.itemTitle) != -1 && (
              <Table.Cell>
                <Dropdown
                  className="mask-selection-dropdown"
                  ref={this.glitterMaskDropdownRef}
                  onChange={(e, d) => {
                    this.setState({ selectedGlitterMask: d.value });
                    this.applyMakeupFromState();
                  }}
                  fluid
                  scrolling
                  selection
                  defaultValue={this.getDefaultGlitterMask()}
                  options={GlitterMasks[this.props.itemTitle]}
                />
              </Table.Cell>
            )}
            {Object.keys(GlitterMasks).indexOf(this.props.itemTitle) != -1 && (
              <Table.Cell collapsing>
                <Slider
                  ref={this.glitterIntensityRef}
                  defaultValue={0}
                  min={0}
                  max={100}
                  step={5}
                  handle={handle}
                  onAfterChange={(val) => {
                    this.setState({ glitterIntensityValue: val });
                    this.applyMakeupFromState();
                  }}
                />
              </Table.Cell>
            )}
            {Object.keys(ShimmerMasks).indexOf(this.props.itemTitle) != -1 && (
              <Table.Cell>
                <Dropdown
                  className="mask-selection-dropdown"
                  ref={this.shimmerMaskDropdownRef}
                  onChange={(e, d) => {
                    this.setState({ selectedShimmerMask: d.value });
                    this.applyMakeupFromState();
                  }}
                  fluid
                  scrolling
                  selection
                  defaultValue={this.getDefaultShimmerMask()}
                  options={ShimmerMasks[this.props.itemTitle]}
                />
              </Table.Cell>
            )}
            {Object.keys(ShimmerMasks).indexOf(this.props.itemTitle) != -1 && (
              <Table.Cell collapsing>
                <Slider
                  ref={this.shimmerIntensityRef}
                  defaultValue={0}
                  min={0}
                  max={100}
                  step={5}
                  handle={handle}
                  onAfterChange={(val) => {
                    this.setState({ shimmerIntensityValue: val });
                    this.applyMakeupFromState();
                  }}
                />
              </Table.Cell>
            )}
            
{this.state.showBlushShimmerColor && (
                  <Table.Cell id="color-picker" collapsing>
                  <CustomButton size="mini" onClick={this.showProductEditColorPicker}>
                    <SelectColorText>Select Color</SelectColorText>
                    <ColorIndicator
                      style={{
                        backgroundColor: `${this.getBackgroundColorForBlushShimmer()}`,
                      }}
                    ></ColorIndicator>
                  </CustomButton>
                  {this.state.isProductEditColorPickerActive && (
                    <PopOver>
                      {false && (
                        <Cover
                          onClick={() =>
                            this.setState({
                              activeEdit: {
                                ...this.state.activeEdit,
                              },
                              isProductEditColorPickerActive: false,
                            })
                          }
                        ></Cover>
                      )}
                      <SketchPicker
                        color={window.RGBAToHexA(
                          this.state.activeEdit.shimmer_r == null?-1:this.state.activeEdit.shimmer_r,
                          this.state.activeEdit.shimmer_g == null?-1:this.state.activeEdit.shimmer_g,
                          this.state.activeEdit.shimmer_b == null?-1:this.state.activeEdit.shimmer_b,
                          1
                        )}
                        onChangeComplete={(e) => {
                          this.setState({
                              activeEdit: {
                                ...this.state.activeEdit,
                                shimmer_r: e.rgb.r,
                                shimmer_g: e.rgb.g,
                                shimmer_b: e.rgb.b,
                                //a: e.rgb.a,
                              },
                              shimmer_r: e.rgb.r,
                                shimmer_g: e.rgb.g,
                                shimmer_b: e.rgb.b
                          });
                          this.applyMakeupFromState();
                        }}
                      />
                    </PopOver>
                  )}
                </Table.Cell>
                  )}
{this.state.showEyeshadowShimmerColor && (
                  <Table.Cell id="color-picker" collapsing>
                  <CustomButton size="mini" onClick={this.showProductEditColorPicker}>
                    <SelectColorText>Select Color</SelectColorText>
                    <ColorIndicator
                      style={{
                        backgroundColor: `${this.getBackgroundColorForEyeshadowShimmer()}`,
                      }}
                    ></ColorIndicator>
                  </CustomButton>
                  {this.state.isProductEditColorPickerActive && (
                    <PopOver>
                      {false && (
                        <Cover
                          onClick={() =>
                            this.setState({
                              activeEdit: {
                                ...this.state.activeEdit,
                              },
                              isProductEditColorPickerActive: false,
                            })
                          }
                        ></Cover>
                      )}
                      <SketchPicker
                        color={window.RGBAToHexA(
                          this.state.activeEdit.shimmer_r == null?-1:this.state.activeEdit.shimmer_r,
                          this.state.activeEdit.shimmer_g == null?-1:this.state.activeEdit.shimmer_g,
                          this.state.activeEdit.shimmer_b == null?-1:this.state.activeEdit.shimmer_b,
                          this.state.activeEdit.shimmer_a == null?-1:this.state.activeEdit.shimmer_a,
                          // 1
                        )}
                        onChangeComplete={(e) => {
                          this.setState({
                              activeEdit: {
                                ...this.state.activeEdit,
                                shimmer_r: e.rgb.r,
                                shimmer_g: e.rgb.g,
                                shimmer_b: e.rgb.b,
                                shimmer_a: e.rgb.a,
                                //a: e.rgb.a,
                              },
                                shimmer_r: e.rgb.r,
                                shimmer_g: e.rgb.g,
                                shimmer_b: e.rgb.b,
                                shimmer_a: e.rgb.a
                          });
                          this.applyMakeupFromState();
                        }}
                      />
                    </PopOver>
                  )}
                </Table.Cell>
                  )}
            <Table.Cell collapsing singleLine>
              <Button
                onClick={this.addProduct}
                size="mini"
                icon
                labelPosition="left"
              >
                <Icon name="add" />
                Add
              </Button>
              <Button
                onClick={this.clearRender}
                size="mini"
                icon
                labelPosition="left"
              >
                <Icon name="close" />
                Clear
              </Button>
            </Table.Cell>
          </Table.Row>
        </CustomTableBody>
      </Table>
    );
  }
}

DynamicTable = observer(DynamicTable);
export default DynamicTable;